import { useEffect, useContext, useCallback } from 'react';
import useStyles from './AssignBadgesStyles';
import { AssignBadgeContext } from '../../../contexts/AssignBadgeContext';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import { BadgeListView } from '../../../components/BadgeListView/BadgeListView';
import { UserDataContext } from '../../../contexts/UserDataContext';
import { SDK_PATHS } from '../../../constants/SDK_PATHS';
import nft_image from '../../../assets/nft.jpg';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { Link } from 'react-router-dom';

export const AssignBadges: React.FC = () => {
  const classes = useStyles();
  const { dispatch, setLoading } = useContext(LoaderContext);
  const { state, fetchBadgesAPI, approveRejectBadgeAPI } =
    useContext(AssignBadgeContext);
  const { userdatastate } = useContext(UserDataContext);

  const fetchBadges = useCallback(async () => {
    await fetchBadgesAPI();
  }, [userdatastate.userData]);
  useEffect(() => {
    if (!userdatastate.userData) {
      dispatch(setLoading(true));
    } else {
      fetchBadges();
      dispatch(setLoading(false));
    }
  }, [userdatastate.userData]);
  useEffect(() => {
    fetchBadges();
  }, [fetchBadges, userdatastate.userData]);

  const approveRejectButton = async (
    isApproved: boolean,
    badgeId: any,
    contractBadgeId: string,
    comments: string,
    selectedBadgeDetails: any
  ) => {
    await approveRejectBadgeAPI(
      isApproved,
      badgeId,
      contractBadgeId,
      comments,
      selectedBadgeDetails
    );
    await fetchBadgesAPI();
  };
  const columns: GridColDef[] = [
    {
      field: 'imageIpfsHash',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      maxWidth: 50,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <img
            src={`${SDK_PATHS.badges['manage'].getImage}/${params.row.imageIpfsHash}`}
            onError={(e: any) => {
              e.target.src = nft_image;
            }}
            className={classes.nftImage}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'NFT Name',
      headerAlign: 'left',
      align: 'left',
      width: 160,
    },

    {
      field: 'assignedUserName',
      headerName: 'Assignee name',
      headerAlign: 'left',
      align: 'left',
      width: 160,
      valueGetter: (params: GridRenderCellParams<string>) => {
        return params.row?.assignedUser?.name;
      },
    },
    {
      field: 'assignedUserEmail',
      headerName: 'Assignee Email',
      headerAlign: 'left',
      align: 'left',
      width: 160,
      valueGetter: (params: GridRenderCellParams<string>) => {
        return params.row?.assignedUser?.email;
      },
    },
    {
      field: 'assignedBy',
      headerName: 'Assignee By',
      headerAlign: 'left',
      align: 'left',
      width: 160,
      valueGetter: (params: GridRenderCellParams<string>) => {
        return params.row?.userCreate?.name;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'left',
    },
  ];
  return (
    <Container className={classes.main}>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box className={classes.firstRow}>
            <Box>
              <Typography variant="body1">
                Welcome, {userdatastate.userData?.name}
              </Typography>
              <Typography variant="h1">Assign NFT</Typography>
            </Box>
            <Box className={classes.importExcelBtn}>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/import-excel"
              >
                Import from Excel
              </Button>
            </Box>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <BadgeListView
        badgeslist={state.badgeslist}
        columns={columns}
        approveRejectButton={approveRejectButton}
      ></BadgeListView>
    </Container>
  );
};
