import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme } from '../../theme';
export default makeStyles(() => ({
  nftCountWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${FFColors.BorderGray}`,
    padding: spacetheme.spacing(6, 8),
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '270px',
    marginTop: spacetheme.spacing(11),
    marginBottom: spacetheme.spacing(7),
    backgroundColor: FFColors.White,
    '& hr': {
      borderWidth: '1px',
      borderColor: FFColors.BorderGray,
      height: '46px',
    },
    '& h2': {
      marginBottom: 0,
    },
  },
  errorMsg: {
    color: FFColors.Success,
  },
  errorMsgRed: {
    color: FFColors.Error,
  },
  errorExclamation: {
    background: FFColors.Error,
    width: '18px',
    height: '18px',
    borderRadius: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: FFColors.White,
    margin: '11px',
    marginLeft: '26px',
  },
}));
