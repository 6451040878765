import { createContext, Dispatch, useReducer, useContext } from 'react';
import { SDK_PATHS } from '../constants/SDK_PATHS';
import { UseFetchAPI } from '../utils/UseFetchAPI';
import { ToastMsg, DesignationLevels } from '../constants/constants';
import { SnackbarContext } from './SnackbarContext';
import { UserDataContext } from './UserDataContext';

export interface IAssignmentValidateState {
  validatedAssignmentList: any;
  errors?: Array<any>;
  isError: boolean;
  duplicated?: number;
  category?: string;
}

export const initialItems: IAssignmentValidateState = {
  validatedAssignmentList: {},
  errors: [],
  duplicated: 0,
  isError: false,
  category: '',
};
// Actions
export enum ActionType {
  validatedAssignmentList,
}

export interface setAssignmentValidate {
  type: ActionType.validatedAssignmentList;
  payload: IAssignmentValidateState;
}

export type AssignmentValidateActions = setAssignmentValidate;

//reducer
export const setAssignmentValidate = (
  payload: IAssignmentValidateState
): setAssignmentValidate => ({
  type: ActionType.validatedAssignmentList,
  payload: payload,
});

export const assignmentValidateReducer = (
  state: IAssignmentValidateState,
  action: AssignmentValidateActions
): IAssignmentValidateState => {
  switch (action.type) {
    case ActionType.validatedAssignmentList:
      const mappedAssignmentList = action.payload.validatedAssignmentList.map(
        (assignment: any) => {
          return {
            name: assignment.name,
            email: assignment.email,
            level: assignment.level,
            status: assignment.errors[0]?.msg || 'Success',
            field: assignment.errors[0]?.field,
            isEdit: assignment.errors.msg ? true : false,
            userId: assignment.userId,
            badgeId: assignment.badgeId,
            eventName: assignment.eventName,
            contractBadgeId: assignment.contractBadgeId,
            eventStartDate: assignment.eventStartDate,
            participationLevel: assignment.participationLevel,
            userAddress: assignment.userAddress,
            errors: assignment.errors,
            DesignationLevels,
          };
        }
      );
      const validationState = {
        ...state,
        category: action.payload.category,
        isError: action.payload.isError,
        validatedAssignmentList: mappedAssignmentList,
      };
      return validationState;
    default:
      return state;
  }
};

export const AssignmentValidateContext = createContext<{
  state: IAssignmentValidateState;
  dispatch: Dispatch<AssignmentValidateActions>;
  validateBadgeAssignmentAPI: any;
  submitAssignmentAPI: any;
}>({
  state: initialItems,
  dispatch: () => undefined,
  validateBadgeAssignmentAPI: () => undefined,
  submitAssignmentAPI: () => undefined,
});

const fetchWrapper = UseFetchAPI();

export const AssignmentValidateContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(assignmentValidateReducer, initialItems);
  const { setToster } = useContext(SnackbarContext);
  const { userdatastate } = useContext(UserDataContext);
  const validateBadgeAssignmentAPI = async (
    assignmentList: any,
    categoryType: any
  ) => {
    const payload = {
      type: categoryType,
      assignmentList: assignmentList,
    };
    try {
      let res = await fetchWrapper.post({
        url: SDK_PATHS.badges.assign.validateAssignment,
        body: payload,
        userData: userdatastate.userData,
      });

      res = { ...res, categoryType };
      dispatch(setAssignmentValidate(res));
      return res;
    } catch (err) {
      setToster({
        message: err,
        type: ToastMsg.ERROR,
      });
    }
  };
  const submitAssignmentAPI = async (assignmentList: Array<any>) => {
    const payload = {
      assignedBy: userdatastate.userData?.id,
      assignmentList,
    };
    try {
      const res = await fetchWrapper.post({
        url: SDK_PATHS.badges.assign.post,
        body: payload,
        userData: userdatastate.userData,
      });
      setToster({
        message: `${res.length} ${ToastMsg.BULKASSIGN}`,
        type: ToastMsg.SUCESSS,
      });
      return res;
    } catch (err) {
      setToster({
        message: err,
        type: ToastMsg.ERROR,
      });
    }
  };

  return (
    <AssignmentValidateContext.Provider
      value={{
        state,
        dispatch,
        validateBadgeAssignmentAPI,
        submitAssignmentAPI,
      }}
    >
      {props.children}
    </AssignmentValidateContext.Provider>
  );
};
