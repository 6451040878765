const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api';

export const SDK_PATHS = {
  // Internal
  createWallet: `${API_PREFIX}/internal/createwallet`,
  createBulkWallet: `${API_PREFIX}/internal/createwallet/bulk`,
  // Internal - user
  findOrCreateUser: `${API_PREFIX}/internal/users`,
  // Internal = Badges
  badges: {
    manage: {
      getImage: `${API_PREFIX}/internal/badges/manage/image`,
      get: `${API_PREFIX}/internal/badges/manage`,
      post: `${API_PREFIX}/internal/badges/manage`,
      update: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/${badgeId}/update`,
      approve: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/manage/${badgeId}/approve`,
      reject: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/manage/${badgeId}/reject`,
      bulkapprove: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/manage/approve/bulk`,
      bulkreject: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/manage/reject/bulk`,
    },
    assign: {
      get: `${API_PREFIX}/internal/badges/assign`,
      post: `${API_PREFIX}/internal/badges/assign/bulk`,
      update: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/${badgeId}/update`,
      approve: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/assign/${badgeId}/approve`,
      reject: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/assign/${badgeId}/reject`,
      bulkapprove: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/assign/approve/bulk`,
      bulkreject: (badgeId: string) =>
        `${API_PREFIX}/internal/badges/assign/reject/bulk`,
      validateAssignment: `${API_PREFIX}/internal/badges/assign/validate`,
    },
  },
  //IPFS
  ipfsBase: 'https://u0qmqhgkxr-u0l5lu20nh-ipfs.us0-aws.kaleido.io/ipfs/',
  // Common
  organizations: `${API_PREFIX}/common/organizations`,
  plugins: `${API_PREFIX}/common/plugins`,
  transactionsByID: (txID: string) =>
    `${API_PREFIX}/common/transactions/${txID}`,
  verifiers: `${API_PREFIX}/common/verifiers`,
  // Contracts
  contractsApi: `${API_PREFIX}/contracts/api`,
  contractsApiByName: (apiName: string) =>
    `${API_PREFIX}/contracts/api/${apiName}`,
  contractsInterface: `${API_PREFIX}/contracts/interface`,
  contractsListener: `${API_PREFIX}/contracts/listener`,
  contractsListenerByApiName: (apiName: string) =>
    `${API_PREFIX}/contracts/api/${apiName}/listener`,
  // Messages
  messagesBroadcast: `${API_PREFIX}/messages/broadcast`,
  messagesBroadcastBlob: `${API_PREFIX}/messages/broadcastblob`,
  messagesPrivate: `${API_PREFIX}/messages/private`,
  messagesPrivateBlob: `${API_PREFIX}/messages/privateblob`,
  messagesDatatypes: `${API_PREFIX}/datatypes`,
  messagesDatatypesByNameVersion: (name: string, version: string) =>
    `${API_PREFIX}/datatypes/${name}/${version}`,
  // Tokens
  tokensBalances: `${API_PREFIX}/tokens/balances`,
  tokensBurn: `${API_PREFIX}/tokens/burn`,
  tokensBurnWithBlob: `${API_PREFIX}/tokens/burnblob`,
  tokensMint: `${API_PREFIX}/tokens/mint`,
  tokensMintWithBlob: `${API_PREFIX}/tokens/mintblob`,
  tokensPools: `${API_PREFIX}/tokens/pools`,
  tokensTransfer: `${API_PREFIX}/tokens/transfer`,
  tokensTransferWithBlob: `${API_PREFIX}/tokens/transferblob`,
  // Templates
  template: (category: string, formID: string, isBlob?: boolean) =>
    `${API_PREFIX}/${category}/template/${formID}${isBlob ? 'blob' : ''}`,
};
