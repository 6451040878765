import { useState } from 'react';
import * as XLSX from 'xlsx';
import { Typography, Box } from '@mui/material';
import useStyles from './CSVFileUploadStyles';
import { Sizes } from '../../constants/constants';
import { DragAndDrop } from '../DragAndDrop/DragAndDrop';

export const CSVFileUpload: React.FC<{
  validateDoc: any;
  downloadTemplate: string;
  acceptFiles: any;
  buttonTitle: string;
  errorMessage: string;
}> = ({
  validateDoc,
  downloadTemplate,
  acceptFiles,
  buttonTitle,
  errorMessage,
}) => {
  const [fileName, setFileName] = useState();
  const [data, setData] = useState<object>([]);
  const [fileSize, setFileSize] = useState('');
  const [isValidFile, setIsValidFile] = useState(true);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const classes = useStyles();
  const formatFileSize = (bytes: number, decimalPoint?: number) => {
    if (bytes == 0) return '0 Bytes';
    const k = 1000,
      dm = decimalPoint || 2,
      sizes = Sizes,
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  const fileUploadClose = () => {
    setIsFileUploaded(false);
    // setIsValidFile(true);
    validateDoc(data, false);
  };

  const fileProcessing = (event: any, file: any) => {
    const fileSize = formatFileSize(file.size);
    setFileName(file.name);
    setFileSize(fileSize);
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        cellText: false,
        cellDates: true,
        dateNF: 'yyyy-mm-dd',
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        dateNF: 'yyyy-mm-dd',
      });
      setData(data);
      validateDoc(data, true);
      setIsFileUploaded(true);
      event.target.value = null;
    };
    reader.readAsBinaryString(file);
  };
  return (
    <>
      <Box className={classes.uploadBoxWrapper}>
        <Typography variant="h3" gutterBottom>
          Upload Files
        </Typography>
        <Box className={classes.disclaimerSec}>
          <Typography variant="body2" gutterBottom>
            .XLSX .CSV Only. Max size of 800K
          </Typography>
          <a
            className={classes.downloadTemplate}
            href={downloadTemplate}
            download
          >
            Download Template
          </a>
        </Box>
        <DragAndDrop
          fileProcessing={fileProcessing}
          fileUploadClose={fileUploadClose}
          isFileUploaded={isFileUploaded}
          fileName={fileName}
          acceptFiles={acceptFiles}
          buttonTitle={buttonTitle}
          errorMessage={errorMessage}
        ></DragAndDrop>
      </Box>
    </>
  );
};
