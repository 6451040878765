import { useEffect, useState } from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridState,
  GridRowId,
} from '@mui/x-data-grid';
import React from 'react';
import useStyles from './BadgeDataGridStyle';
import { Pagination, PaginationItem, Typography } from '@mui/material';
import { FFColors } from '../../theme';

interface Props {
  badges: any;
  selecedBadgeList: any;
  columns: any;
  pageSize?: any;
  checkboxSelection: any;
  diableParam: any;
  disableValue: any;
}

const previousComponent = () => {
  return <Typography variant="body2">Previous</Typography>;
};

const nextComponent = () => {
  return <Typography variant="body2">Next</Typography>;
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const [state] = useGridState(apiRef);

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      renderItem={(item) => (
        <PaginationItem
          components={{
            previous: previousComponent,
            next: nextComponent,
          }}
          {...item}
        />
      )}
    />
  );
};

export const BadgeDataGrid: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [badges, setBadges] = useState(props.badges);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  useEffect(() => {
    setBadges(props.badges);
    if (props.badges[0]) {
      setSelectedRows(props.badges[0]?.id);
      props.selecedBadgeList([props.badges[0]]);
    } else {
      props.selecedBadgeList([]);
    }
  }, [props.badges]);

  return (
    <div className={classes.main}>
      <DataGrid
        rows={badges}
        columns={props.columns}
        pageSize={props.pageSize || 5}
        rowsPerPageOptions={[props.pageSize || 5]}
        checkboxSelection={props.checkboxSelection}
        disableColumnMenu={true}
        isRowSelectable={(params) => {
          return !props.disableValue?.includes(params.row[props.diableParam]);
        }}
        selectionModel={selectedRows}
        onRowClick={(data) => {
          if (!props.checkboxSelection) {
            setSelectedRows([data.id]);
            props.selecedBadgeList([data.row]);
          }
        }}
        getRowClassName={(params) => {
          return props.disableValue?.includes(params.row[props.diableParam])
            ? 'disabledRow'
            : '';
        }}
        onSelectionModelChange={(ids) => {
          setSelectedRows(ids);
          const selectedRowData: any = [];
          ids.map((row: any) => {
            badges.map((data: any) => {
              if (data.id === row) {
                selectedRowData.unshift(data);
              }
            });
          });
          props.selecedBadgeList(selectedRowData);
        }}
        components={{
          Pagination: CustomPagination,
        }}
        // TODO: Please move styles to classes if possible
        sx={{
          boxShadow: 2,
          borderColor: 'rgba(0, 0, 0, 0.24)',
          width: '100%',
          opacity: 1,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
          },
          '& .MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '& .MuiCheckbox-root.Mui-checked': {
            color: FFColors.Green,
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF',
          },
          '&>.MuiDataGrid-main': {
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          },
        }}
      />
    </div>
  );
};
