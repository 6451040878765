import { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  CardContent,
  Card,
  CardActionArea,
} from '@mui/material';
import useStyles from './Styles';
import nft_image from '../../../assets/nft.jpg';
import { format } from 'date-fns';

interface Props {
  userBadges: Array<any> | undefined;
  selectedBadge: any;
  type: string;
  selectedBadgeID: string;
}

export const ProfileData: React.FC<Props> = ({
  userBadges,
  selectedBadge,
  type,
  selectedBadgeID,
}) => {
  const [user, setUser] = useState(userBadges);
  useEffect(() => {
    setUser(userBadges);
  }, [userBadges]);
  const classes = useStyles();

  return (
    <Box mb={5}>
      <Grid container spacing={2}>
        {(user || []).map((badge: any, index: any) => (
          <Grid key={index} item xs={12} md={6}>
            <Card
              className={`${
                selectedBadgeID === badge.id
                  ? classes.activeBadge
                  : '' + ' ' + classes.card + ' ' + classes.badgeCard
              }`}
            >
              <CardActionArea
                onClick={() => {
                  selectedBadge(badge);
                }}
              >
                <CardContent className={classes.cardContent}>
                  <img
                    src={`/api/internal/badges/manage/image/${badge.badge.imageIpfsHash}`}
                    className={classes.badgePic}
                    onError={(e: any) => {
                      e.target.src = nft_image;
                    }}
                  />
                  <Box className={classes.boxMargin}>
                    {type === 'Event' ? (
                      <Typography variant="body1" className={classes.badgeName}>
                        {badge.badge?.name}
                      </Typography>
                    ) : null}

                    <Typography variant="h3">
                      <b>{badge.badge?.typeSpecificData.level}</b>
                    </Typography>
                    <Typography variant="body1" className={classes.date}>
                      {type === 'Event' ? 'Event date' : 'Assigned on'}

                      <Typography variant="h4" className={classes.dateText}>
                        {type == 'Event'
                          ? format(
                              new Date(
                                badge.badge.typeSpecificData.eventStartDate
                              ),
                              'MM-dd-yyyy'
                            )
                          : format(new Date(badge.updatedAt), 'MM-dd-yyyy')}
                      </Typography>
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
