import { Box, CircularProgress } from '@mui/material';
import useStyles from './Styles';

export const FFCircularProgress: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress className={classes.loaderColor} />
    </Box>
  );
};
