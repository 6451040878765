import { styled } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { EventContext } from './contexts/EventContext';
import { FF_EVENTS, FINISHED_EVENT_SUFFIX } from './ff_models/eventTypes';
import { FF_TX_CATEGORY_MAP } from './ff_models/transactionTypes';
import { IEvent } from './interfaces/api';
import { IEventHistoryItem } from './interfaces/events';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { FFColors } from './theme';
import { UserDataContext } from './contexts/UserDataContext';
import { Footer } from './components/Footer/Footer';

const Main = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',
});

const RootDiv = styled('div')({
  display: 'flex',
  backgroundColor: FFColors.BgGray,
  height: 'inherit',
});

export const ACTION_QUERY_KEY = 'action';
export const ACTION_DELIM = '.';

export const AppWrapper: React.FC = () => {
  const {
    userdatastate,
    fetchGraphDataApi,
    fetchUserDataApi,
    getUserProfilePicture,
  } = useContext(UserDataContext);
  const isAuthenticated = useIsAuthenticated();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [logHistory, setLogHistory] = useState<Map<string, IEventHistoryItem>>(
    new Map()
  );
  const [awaitedEventID, setAwaitedEventID] = useState<string | undefined>(
    undefined
  );
  const [refreshBalances, setRefreshBalances] = useState(new Date().toString());
  const [refreshAPIs, setRefreshAPIs] = useState(new Date().toString());
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (pathname != '/sign-in') {
      setIsSignIn(false);
    } else {
      setIsSignIn(true);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (account && inProgress === 'none' && !userdatastate.graphData) {
      getUserProfilePicture(account);
    }
  }, [account, inProgress, instance]);

  const fetchGraphData = useCallback(
    async (account) => {
      fetchGraphDataApi(account);
    },
    [account]
  );
  useEffect(() => {
    if (account && inProgress === 'none' && !userdatastate.graphData) {
      fetchGraphData(account);
    }
  }, [account, inProgress, instance]);

  const fetchUserData = useCallback(async (graphData) => {
    fetchUserDataApi(graphData);
  }, []);
  useEffect(() => {
    if (userdatastate.graphData && userdatastate.graphData['mail']) {
      fetchUserData(userdatastate.graphData);
    }
  }, [userdatastate.graphData]);

  const isFinalEvent = (event: IEvent) => {
    if (
      event.type.endsWith(FINISHED_EVENT_SUFFIX.CONFIRMED) ||
      event.type.endsWith(FINISHED_EVENT_SUFFIX.SUCCEEDED) ||
      event.type.endsWith(FINISHED_EVENT_SUFFIX.REJECTED) ||
      event.type.endsWith(FINISHED_EVENT_SUFFIX.FAILED)
    )
      return true;

    if (event.reference === awaitedEventID || event.correlator) return true;

    return false;
  };

  const isFailed = (t: string) => {
    return (
      t.endsWith(FINISHED_EVENT_SUFFIX.REJECTED) ||
      t.endsWith(FINISHED_EVENT_SUFFIX.FAILED)
    );
  };

  const addLogToHistory = (event: IEvent) => {
    // Update balance and API boxes, if those events are confirmed
    if (event.type === FF_EVENTS.TOKEN_TRANSFER_CONFIRMED)
      setRefreshBalances(new Date().toString());
    if (event.type === FF_EVENTS.CONTRACT_API_CONFIRMED)
      setRefreshAPIs(new Date().toString());

    setLogHistory((logHistory) => {
      // Must deep copy map since it has nested json data
      const deepCopyMap: Map<string, IEventHistoryItem> = new Map(
        JSON.parse(JSON.stringify(Array.from(logHistory)))
      );
      const txMap = deepCopyMap.get(event.tx);

      // If transaction is already in map, append the event
      if (txMap !== undefined) {
        if (isFailed(event.type) || isFinalEvent(event)) {
          setAwaitedEventID(undefined);
        }
        return new Map(
          deepCopyMap.set(event.tx, {
            events: [event, ...txMap.events],
            created: event.created,
            isComplete: isFinalEvent(event),
            isFailed: isFailed(event.type),
            showIcons: txMap.showIcons,
            showTxHash: txMap.showTxHash,
            txName: txMap.txName,
          })
        );
      }

      const newEvent: IEventHistoryItem = {
        events: [event],
        created: event.created,
        isComplete: isFinalEvent(event),
        isFailed: isFailed(event.type),
        showIcons: true,
        showTxHash: true,
        txName: event.transaction?.type
          ? t(FF_TX_CATEGORY_MAP[event.transaction.type].nicename)
          : t('none'),
      };

      // If transactionID is unknown
      if (event.tx === undefined) {
        return new Map(
          deepCopyMap.set(event.id, {
            ...newEvent,
            showIcons: false,
            showTxHash: false,
            txName: t('none'),
          })
        );
      }

      return new Map(
        deepCopyMap.set(event.tx, {
          ...newEvent,
          showIcons: event.pool ? event.pool.dataSupport : true,
        })
      );
    });
  };

  const addAwaitedEventID = (apiRes: any) => {
    if (apiRes?.id && apiRes?.id) {
      setAwaitedEventID(apiRes.id);
    }
  };

  if (pathname !== '/sign-in') {
    if (!isAuthenticated) {
      return <Navigate to="/sign-in" replace={false} />;
    }
  }

  if (pathname === '/sign-in') {
    if (isAuthenticated) {
      return <Navigate to="/profile" replace={false} />;
    }
  }

  return (
    <RootDiv>
      <Main>
        <EventContext.Provider
          value={{
            logHistory,
            addLogToHistory,
            addAwaitedEventID,
            awaitedEventID,
            refreshBalances,
            refreshAPIs,
            isSignIn,
          }}
        >
          <Header></Header>
          <Outlet />
          <Footer></Footer>
        </EventContext.Provider>
      </Main>
    </RootDiv>
  );
};
