import { createContext, Dispatch, SetStateAction } from 'react';
import { IApiStatus, ISelfIdentity } from '../interfaces/api';

export interface IApplicationContext {
  selfIdentity: ISelfIdentity | undefined;
  // Json Payload
  jsonPayload: object;
  setJsonPayload: Dispatch<SetStateAction<object>>;
  payloadMissingFields: boolean;
  setPayloadMissingFields: Dispatch<SetStateAction<boolean>>;
  // API Response
  apiResponse: object;
  setApiResponse: Dispatch<SetStateAction<object>>;
  // API Status
  apiStatus: IApiStatus | undefined;
  setApiStatus: Dispatch<SetStateAction<IApiStatus | undefined>>;
  // Plugins
  tokensDisabled: boolean;
  blockchainPlugin: string;
}

export const ApplicationContext = createContext({} as IApplicationContext);
