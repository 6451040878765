import { useEffect, useContext, useCallback } from 'react';
import {
  Button,
  Divider,
  Typography,
  Box,
  Grid,
  Container,
} from '@mui/material';
import useStyles from './ManageBadgesStyles';
import { ManageBadgeContext } from '../../../contexts/ManageBadgeContext';
import { SDK_PATHS } from '../../../constants/SDK_PATHS';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { BadgeListView } from '../../../components/BadgeListView/BadgeListView';
import { UserDataContext } from '../../../contexts/UserDataContext';
import nft_image from '../../../assets/nft.jpg';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { Link } from 'react-router-dom';

export const ManageBadges: React.FC = () => {
  const classes = useStyles();
  const { dispatch, setLoading } = useContext(LoaderContext);
  const { state, fetchBadgesAPI, approveRejectBadgeAPI } =
    useContext(ManageBadgeContext);
  const { userdatastate } = useContext(UserDataContext);
  const fetchBadges = useCallback(async () => {
    await fetchBadgesAPI();
  }, [userdatastate.userData]);
  useEffect(() => {
    if (!userdatastate.userData) {
      dispatch(setLoading(true));
    } else {
      fetchBadges();
      dispatch(setLoading(false));
    }
  }, [userdatastate.userData]);

  const approveRejectButton = async (
    isApproved: boolean,
    badgeId: any,
    contractBadgeId: string,
    comments: string,
    selectedBadgeDetails: any
  ) => {
    dispatch(setLoading(true));
    await approveRejectBadgeAPI(
      isApproved,
      badgeId,
      contractBadgeId,
      selectedBadgeDetails,
      comments
    );
    await fetchBadgesAPI();
    dispatch(setLoading(false));
  };
  const columns: GridColDef[] = [
    {
      field: 'imageIpfsHash',
      headerName: '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 60,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <img
            src={`${SDK_PATHS.badges['manage'].getImage}/${params.row.imageIpfsHash}`}
            onError={(e: any) => {
              e.target.src = nft_image;
            }}
            className={classes.nftImage}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'NFT Name',
      minWidth: 80,
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      renderHeader: (params: GridColumnHeaderParams) => {
        const { field, colDef } = params;
        // const sort = params.colDef.sortable;

        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef.width}
            />
            {/* <div className="iconButtonContainer">
              <img src={sorticon} />
            </div> */}
          </>
        );
      },
    },

    {
      field: 'createdAt',
      headerName: 'Date Created',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.createdAt
          ? new Date(params.row.createdAt).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : '';
      },
    },
    {
      field: 'createdByName',
      headerName: 'Created by',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row?.userCreate?.name;
      },
    },
    // {
    //   field: 'rewards',
    //   headerName: 'Green Dot',
    //   headerAlign: 'center',
    //   align: 'center',
    //   minWidth: 80,
    //   flex: 2,
    // },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      flex: 2,
    },
  ];
  return (
    <Container className={classes.main}>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box className={classes.firstRow}>
            <Box>
              <Typography component="span" variant="body1">
                Welcome,
                {userdatastate.userData?.name.replace(/,/g, '')}
              </Typography>
              <Typography variant="h1" component="h1">
                Manage NFT
              </Typography>
            </Box>
            <Box className={classes.createNftBtn}>
              <Button
                variant="contained"
                to="/create-badge"
                color="secondary"
                component={Link}
              >
                Create an NFT
              </Button>
            </Box>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <BadgeListView
        badgeslist={state.badgeslist}
        columns={columns}
        approveRejectButton={approveRejectButton}
      ></BadgeListView>
    </Container>
  );
};
