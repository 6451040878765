import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { AppWrapper } from '../AppWrapper';

import { SignIn } from '../pages/SignIn/views/SignIn';
import { Profile } from '../pages/Profile/views/Profile';
import { ManageBadges } from '../pages/ManageBadges/views/ManageBadges';
import { CreateBadge } from '../pages/CreateBadges/views/CreateBadge';
import { AssignBadges } from '../pages/AssignBadges/views/AssignBadges';
import { ImportExcel } from '../pages/ImportExcel/ImportExcel';
import { ProtectedRoute } from './ProtectedRoute';
import { useContext, useState, useEffect } from 'react';
import { UserDataContext } from '../contexts/UserDataContext';
export const FF_Router: () => JSX.Element = () => {
  const isAdmin = sessionStorage.getItem('isAdmin');
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Routes>
        <Route element={<AppWrapper />}>
          <Route path="/" element={<Navigate to="/profile" replace />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/manage-badges"
            element={
              <ProtectedRoute>
                <ManageBadges />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-badge"
            element={
              <ProtectedRoute>
                <CreateBadge />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign-badges"
            element={
              <ProtectedRoute>
                <AssignBadges />
              </ProtectedRoute>
            }
          />
          <Route
            path="/import-excel"
            element={
              <ProtectedRoute>
                <ImportExcel />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
