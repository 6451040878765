import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme } from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  excelPreviewSection: {
    border: `1px solid ${FFColors.BorderGray}`,
    boxShadow: 'none',
    borderRadius: 0,
    '& thead': {
      '& .MuiTableCell-root.MuiTableCell-head': {
        color: FFColors.TextGray,
        backgroundColor: FFColors.White,
      },
    },
    minWidth: 650,
    maxHeight: 600,
    [theme.breakpoints.down('md')]: {
      minWidth: 100,
    },
  },
  btnWrapperDivider: {
    marginTop: spacetheme.spacing(12),
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacetheme.spacing(6),
    marginBottom: spacetheme.spacing(14),
  },
  textfeild: {
    width: '100%',
    '& .MuiInputLabel-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#000',
      '-webkit-text-fill-color': '#000',
    },
    '& .MuiSelect-nativeInput[disabled]': {
      color: '#000',
    },
  },
  iconStyle: {
    cursor: 'pointer',
  },
}));
