import { makeStyles } from '@mui/styles';
import { spacetheme, FFColors } from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  selectBoxTitle: {
    color: FFColors.TextGray,
    marginBottom: spacetheme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: '-13px',
    },
  },
  selectBoxPlaceHolder: {
    color: FFColors.Placeholder,
  },
  selectBoxRoot: {
    width: '100%',
    backgroundColor: FFColors.White,

    paddingRight: spacetheme.spacing(0),
    position: 'relative',
    borderRadius: 0,
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      position: 'absolute',
      right: '8px',
      cursor: 'pointer',
    },
  },
}));
