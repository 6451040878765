import { makeStyles } from '@mui/styles';
import { FFColors, FONT_SIZE, spacetheme } from '../../theme';

export default makeStyles(() => ({
  uploadedImage: {
    position: 'relative',
    maxWidth: '150px',
    '& img': {
      width: '100%',
      display: 'block',
      marginBottom: spacetheme.spacing(3),
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
      position: 'absolute',
      top: '-4px',
      right: '-16px',
    },
  },
  validateMsg: {
    color: FFColors.Red,
  },
  imageuploadSec: {
    background: FFColors.White,
    border: `1px dashed ${FFColors.BorderGray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacetheme.spacing(17, 3),
    marginBottom: spacetheme.spacing(4),
    maxWidth: '150px',
  },
  fileuploadSec: {
    background: FFColors.White,
    border: `1px dashed ${FFColors.BorderGray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacetheme.spacing(20, 21),
    marginBottom: spacetheme.spacing(4),
  },

  fileNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacetheme.spacing(9),
    alignItems: 'center',
    marginBottom: spacetheme.spacing(3),
  },
  fileNameSec: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      width: '24px',
      height: '24px',
      backgroundColor: FFColors.TextGray,
      display: 'inline-block',
    },
    '& p': {
      color: FFColors.TextGray,
      marginLeft: spacetheme.spacing(4),
      marginBottom: 0,
    },
  },
  closeIcon: {
    width: '14px',
    height: '14px',
    cursor: 'pointer',
  },
  fileNameDivider: {
    borderTop: '4px solid',
    borderColor: FFColors.BorderGray,
  },
  fileNameMsg: {
    color: FFColors.Success,
    marginTop: spacetheme.spacing(2),
  },
  errorMessage: {
    color: FFColors.Red,
    marginTop: spacetheme.spacing(2),
  },
}));
