import { Box, Tabs, Tab, Grid, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { BadgeDataGrid } from '../BadgeDataGrid/BadgeDataGrid';
import useStyles from './BadgeListViewStyle';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { BadgeSelectedDetails } from '../BadgeSelectedDetails/BadgeSelectedDetails';
import React from 'react';
import { STATUS, BADGE_CATEGORY } from '../../constants/constants';
import { SelectBox } from '../SelectBox/SelectBox';
import { NoDataFound } from '../NoDataFound/NoDataFound';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Props {
  badgeslist: any;
  columns: any;
  approveRejectButton: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const BadgeListView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [badgeCategory, setBadgeCategory] = useState('Event');
  const [badgeslist, setBadgeslist] = useState({
    newBadges: [],
    approvedBadges: [],
    rejectedBadges: [],
  });
  const [tabvalue, setTabValue] = useState(0);
  const [selectedBadgeDetails, setSelectedBadgeDetails] = useState([]);

  const badgeCategoryChange = (value: string) => {
    badgeCategoryFilter(value);
    setBadgeCategory(value);
    setSelectedBadgeDetails([]);
  };

  useEffect(() => {
    badgeCategoryFilter(badgeCategory);
  }, [props.badgeslist]);
  const sortArray = (a: any, b: any) => {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  };
  const badgeCategoryFilter = (value: string) => {
    const badgeslistArry = {
      newBadges: props.badgeslist?.newBadges
        .sort(sortArray)
        .filter((data: any) => data.type === value),
      approvedBadges: props.badgeslist?.approvedBadges.filter(
        (data: any) => data.type === value
      ),
      rejectedBadges: props.badgeslist?.rejectedBadges.filter(
        (data: any) => data.type === value
      ),
    };
    setBadgeslist(badgeslistArry);
  };
  const TabWithCount = ({ title, count }: any) => {
    return (
      <>
        <Box className={classes.tabBoxSec}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="h2">{count}</Typography>
        </Box>
        <Divider
          className={classes.verticalDivider}
          orientation="vertical"
          variant="middle"
          flexItem
        />
      </>
    );
  };

  const selecedBadgeList = (list: any) => {
    let badgeData: any;
    if (badgeCategory) badgeData = list;
    setSelectedBadgeDetails(badgeData);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSelectedBadgeDetails([]);
  };

  return (
    <Box className={classes.main}>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box className={classes.tabHeaderSec}>
            <Box className={classes.tabAndSelect}>
              <Box className={classes.selectBoxSection}>
                <SelectBox
                  title={'Data by NFT category'}
                  selectBoxChange={badgeCategoryChange}
                  list={BADGE_CATEGORY}
                  defaultValue={'Event'}
                  placeholder={'Select from list'}
                />
              </Box>

              <Tabs
                value={tabvalue}
                onChange={handleChange}
                className={`${
                  tabvalue === 0
                    ? classes.pending
                    : tabvalue === 1
                    ? classes.rejected
                    : tabvalue === 2
                    ? classes.approved
                    : ''
                }`}
              >
                <Tab
                  className={classes.tabBox}
                  label={
                    <TabWithCount
                      count={badgeslist.newBadges.length}
                      title={'Pending'}
                    />
                  }
                />
                <Tab
                  className={classes.tabBox}
                  label={
                    <TabWithCount
                      count={badgeslist.rejectedBadges.length}
                      title={'Rejected'}
                    />
                  }
                />
                <Tab
                  className={classes.tabBox}
                  label={
                    <TabWithCount
                      count={badgeslist.approvedBadges.length}
                      title={'Approved'}
                    />
                  }
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={9} className={classes.tabpanelsection}>
          <TabPanel value={tabvalue} index={0}>
            {badgeslist?.newBadges?.length === 0 ? (
              <Box className={classes.noBadges}>
                <NoDataFound
                  icon={<SearchOffIcon />}
                  title="No pending approvals found"
                  subTitle="You would need to create an NFT to view data here"
                ></NoDataFound>
              </Box>
            ) : (
              <BadgeDataGrid
                badges={badgeslist.newBadges}
                columns={props.columns}
                checkboxSelection={true}
                selecedBadgeList={selecedBadgeList}
                diableParam={'status'}
                disableValue={[STATUS.PROCESSING]}
              />
            )}
          </TabPanel>
          <TabPanel value={tabvalue} index={1}>
            {badgeslist?.rejectedBadges?.length === 0 ? (
              <Box className={classes.noBadges}>
                <NoDataFound
                  icon={<SearchOffIcon />}
                  title="No pending approvals found"
                  subTitle="You would need to create an NFT to view data here"
                ></NoDataFound>
              </Box>
            ) : (
              <BadgeDataGrid
                badges={badgeslist.rejectedBadges}
                columns={props.columns}
                checkboxSelection={false}
                selecedBadgeList={selecedBadgeList}
                diableParam={'status'}
                disableValue={[STATUS.APPROVED, STATUS.CREATED]}
              />
            )}
          </TabPanel>
          <TabPanel value={tabvalue} index={2}>
            {badgeslist?.approvedBadges?.length === 0 ? (
              <Box className={classes.noBadges}>
                <NoDataFound
                  icon={<SearchOffIcon />}
                  title="No pending approvals found"
                  subTitle="You would need to create an NFT to view data here"
                ></NoDataFound>
              </Box>
            ) : (
              <BadgeDataGrid
                badges={badgeslist.approvedBadges}
                columns={props.columns}
                checkboxSelection={false}
                selecedBadgeList={selecedBadgeList}
                diableParam={'status'}
                disableValue={[STATUS.CREATED, STATUS.REJECTED]}
              />
            )}
          </TabPanel>
          {/* </>
          )} */}
        </Grid>
        {selectedBadgeDetails.length === 0 ? null : (
          <Grid item xs={12} md={3} className={classes.badgedetailsGridWrapper}>
            <BadgeSelectedDetails
              badgeData={selectedBadgeDetails}
              badgeSelectedLength={selectedBadgeDetails.length}
              badgeCategory={badgeCategory}
              approveReject={props.approveRejectButton}
            ></BadgeSelectedDetails>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
