import { DescriptionOutlined, GitHub } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Menu,
  styled,
  Toolbar,
  Avatar,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignInButton } from './SignInButton';
import { useTranslation } from 'react-i18next';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { ReactComponent as DiscordLogo } from '../assets/Discord-Logo-White.svg';
import { ResourceUrls } from '../constants/ResourceUrls';
import { EventContext } from '../contexts/EventContext';
import { UserDataContext } from '../contexts/UserDataContext';
import { FF_EVENTS } from '../ff_models/eventTypes';
import { DEFAULT_PADDING } from '../theme';
import { MenuLogo } from './Logos/MenuLogo';
import { FFMenuItem } from './Menus/FFMenuItem';
import { InstructionModal } from './Modals/InstructionModal';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { FFColors } from '../theme';
import useStyles from './HeaderStyle';

const WS_PATH = process.env.REACT_APP_WS_PATH || '/api/ws';
const menuID = 'ff-dropdown-menu';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();

  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  const { userdatastate } = useContext(UserDataContext);
  const { addLogToHistory, isSignIn } = useContext(EventContext);
  const [wsConnected, setWsConnected] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const webSocket = useRef<ReconnectingWebSocket | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!isSignIn) {
      // connectToWS();
    }
  }, [isSignIn]);
  useEffect(() => {
    if (userdatastate.userData) {
      (userdatastate.userData?.roles || []).forEach((element) => {
        if (element.name === 'super-admin') {
          setIsAdmin(true);
          sessionStorage.setItem('isAdmin', 'true');
        }
      });
    }
  }, [userdatastate.userData]);
  const StyledDiscordLogo = styled(DiscordLogo)({
    width: 20,
    height: 20,
  });

  const connectToWS = () => {
    if (!wsConnected) {
      // Open websocket
      webSocket.current = new ReconnectingWebSocket(
        process.env.NODE_ENV === 'development'
          ? `ws://localhost:3001${WS_PATH}`
          : `${window.location.protocol.startsWith('https') ? 'wss' : 'ws'}://${
              window.location.host
            }${WS_PATH}`
      );
      // On Open
      webSocket.current.onopen = function () {
        setWsConnected(true);
      };
      // On Message
      webSocket.current.onmessage = (message: any) => {
        const eventData = JSON.parse(message.data);
        const eventType: FF_EVENTS = eventData.type;
        if (Object.values(FF_EVENTS).includes(eventType)) {
          addLogToHistory(eventData);
        }
      };
    } else {
      // Close websocket
      webSocket.current?.close();
      setWsConnected(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      icon: <DescriptionOutlined />,
      title: t('docs'),
      url: ResourceUrls.fireflyTutorial,
    },
    {
      icon: <GitHub />,
      title: t('github'),
      url: ResourceUrls.sandBoxGH,
    },
    {
      icon: <StyledDiscordLogo />,
      title: t('discord'),
      url: ResourceUrls.fireflyDiscordInvite,
    },
  ];

  const dropdownMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuID}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems.map((item) => (
        <FFMenuItem
          key={item.title}
          icon={item.icon}
          title={item.title}
          url={item.url}
        />
      ))}
    </Menu>
  );

  const navigateToProfile = () => {
    navigate('/profile');
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          px: DEFAULT_PADDING,
        }}
      >
        <Toolbar disableGutters>
          <Grid
            container
            className={classes.maincontainer}
            direction="row"
            alignItems={'center'}
          >
            <Grid item container justifyContent={'flex-start'} xs={8} md={6}>
              <MenuLogo />
            </Grid>
            {!isSignIn ? (
              <Grid
                item
                container
                justifyContent={'flex-end'}
                xs={4}
                md={6}
                alignItems="center"
              >
                <Grid display="flex" alignItems="center">
                  {isAdmin ? (
                    <>
                      <Box pl={1} pr={1} className={classes.nftBtn}>
                        <Button
                          component={Link}
                          to="/manage-badges"
                          type="button"
                          variant="contained"
                          color={
                            location.pathname === '/manage-badges' ||
                            location.pathname === '/create-badge'
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          Manage
                        </Button>
                      </Box>
                      <Box pl={1} pr={1} className={classes.nftBtn}>
                        <Button
                          component={Link}
                          to="/assign-badges"
                          type="button"
                          variant="contained"
                          color={
                            location.pathname === '/assign-badges' ||
                            location.pathname === '/import-excel'
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          Assign
                        </Button>
                      </Box>
                    </>
                  ) : null}

                  <Box pl={4} pr={1} className={classes.profileBox}>
                    {isAuthenticated ? (
                      <>
                        <Box
                          style={{ cursor: 'pointer' }}
                          onClick={navigateToProfile}
                        >
                          <Avatar
                            sx={{ bgcolor: FFColors.Gray, borderRadius: 0 }}
                          >
                            {userdatastate.graphData?.displayName?.[0]}
                          </Avatar>
                        </Box>
                      </>
                    ) : (
                      <SignInButton />
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {isModalOpen && (
        <InstructionModal
          isOpen={isModalOpen}
          handleModalOpen={(open: boolean) => setIsModalOpen(open)}
        />
      )}
      {dropdownMenu}
    </>
  );
};
