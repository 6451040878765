import { Box, Typography, Button, Divider } from '@mui/material';
import useStyles from './DragAndDropStyles';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';

export const DragAndDrop: React.FC<{
  fileProcessing: any;
  fileUploadClose: any;
  isFileUploaded: boolean;
  fileName: any;
  acceptFiles: any;
  buttonTitle: string;
  AcceptsImageFormate?: boolean;
  errorMessage?: any;
}> = ({
  fileProcessing,
  fileUploadClose,
  isFileUploaded,
  fileName,
  acceptFiles,
  buttonTitle,
  AcceptsImageFormate,
  errorMessage,
}) => {
  const classes = useStyles();
  const [isValidFile, setIsValidFile] = useState(true);

  const handleFileUpload = (event: any) => {
    setIsValidFile(true);
    const file = event.target.files[0];
    fileProcessing(event, file);
  };

  const processFile = (event: any) => {
    setIsValidFile(true);
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    (AcceptsImageFormate && file.type.indexOf('image/') !== 0) ||
    (!AcceptsImageFormate &&
      file.type.indexOf(
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) !== 0)
      ? setIsValidFile(false)
      : fileProcessing(event, file);
  };

  const allowDrop = (event: any) => {
    event.preventDefault();
  };
  return (
    <>
      {isFileUploaded && AcceptsImageFormate ? (
        <Box className={classes.uploadedImage}>
          <img src={fileName} />
          <CloseOutlinedIcon onClick={fileUploadClose} />
        </Box>
      ) : (
        <Box
          className={
            AcceptsImageFormate ? classes.imageuploadSec : classes.fileuploadSec
          }
          onDrop={processFile}
          onDragOver={allowDrop}
        >
          <FileUploadOutlinedIcon />
          <Typography variant="body2" gutterBottom>
            Drag and Drop here or
          </Typography>
        </Box>
      )}
      {!isValidFile ? (
        <Typography className={classes.validateMsg}>
          Please upload valid file format
        </Typography>
      ) : (
        ''
      )}
      <Button variant="contained" component="label">
        {buttonTitle}
        <input
          hidden
          type="file"
          accept={acceptFiles}
          onChange={handleFileUpload}
        />
      </Button>
      {isFileUploaded && !AcceptsImageFormate ? (
        <Box>
          <Box className={classes.fileNameWrapper}>
            <Box className={classes.fileNameSec}>
              <span></span>
              <Typography variant="body1" gutterBottom>
                {fileName}
              </Typography>
            </Box>
            <CloseOutlinedIcon
              className={classes.closeIcon}
              onClick={fileUploadClose}
            />
          </Box>
          <Divider className={classes.fileNameDivider} />
          {!errorMessage ? (
            <Typography
              variant="body2"
              className={classes.fileNameMsg}
              gutterBottom
            >
              Successfully uploaded
            </Typography>
          ) : (
            <Typography
              variant="body2"
              className={classes.errorMessage}
              gutterBottom
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      ) : null}
    </>
  );
};
