import { useContext, useEffect, useState } from 'react';
import useStyles from './Styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { UserDataContext } from '../../../contexts/UserDataContext';
import { ProfileData } from './ProfileData';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { BadgeSelectedDetails } from '../../../components/BadgeSelectedDetails/BadgeSelectedDetails';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { NoDataFound } from '../../../components/NoDataFound/NoDataFound';

const ProfileImages = () => {
  const classes = useStyles();
  const { userdatastate } = useContext(UserDataContext);
  const { state, dispatch, setLoading } = useContext(LoaderContext);
  const [badgeData, setBadgeData] = useState([]);
  const [badgeCategory, setBadgeCategory] = useState('');
  const [selectedBadgeID, setSelectedBadgeID] = useState('');
  useEffect(() => {
    if (!userdatastate.userData) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [userdatastate.userData]);

  const designationBadges = userdatastate.userData?.userBadges?.filter(
    (data: any) => data.badge?.type === 'Designation'
  );
  const eventBadges = userdatastate.userData?.userBadges?.filter(
    (data: any) => data.badge?.type === 'Event'
  );

  const selectedBadge = (data: any) => {
    setSelectedBadgeID(data.id);
    const badge: any = [
      {
        ...data.badge,
        userApproved: data.approveUser,
        userAssigned: data.assignUser,
      },
    ];
    setBadgeData(badge);
    setBadgeCategory(data.badge.type);
  };
  return (
    <>
      <Box className={classes.profileImageMain}>
        <Typography className={classes.profileImageBackground}></Typography>
        <Box className={classes.profileImageContainers}>
          <Typography variant="h1">Profile</Typography>
          <Grid container item xs={12} md={8}>
            <Divider
              sx={{ mt: 2, mb: 2 }}
              className={classes.divider}
            ></Divider>
          </Grid>
          <Grid container spacing={2} className={classes.profileImageGrid}>
            <Grid container item xs={12} md={3}>
              <Card className={classes.card}>
                <CardContent>
                  <Box className={classes.firstContainer}>
                    {userdatastate.imageUrl ? (
                      <img
                        className={classes.image}
                        src={userdatastate.imageUrl}
                      />
                    ) : (
                      <Avatar className={classes.avatar} variant="square">
                        {userdatastate.graphData?.displayName?.[0]}
                      </Avatar>
                    )}
                    <Box pl={1} className={classes.welcome}>
                      <Typography variant="h3" className={classes.welcomeText}>
                        Welcome
                      </Typography>
                      {userdatastate.userData?.name ? (
                        <Typography variant="h3">
                          {userdatastate.userData?.name}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                  <Divider
                    sx={{ mt: 2, mb: 2 }}
                    className={classes.dividerColor}
                  />
                  <Box className={classes.basicUserData}>
                    <Box>
                      <Typography variant="body2" className={classes.label}>
                        Level
                      </Typography>
                      <Typography sx={{ mb: 2 }} variant="body1">
                        {userdatastate.graphData?.jobTitle}
                      </Typography>
                    </Box>
                    <Box>
                      {userdatastate.graphData?.mobilePhone ? (
                        <>
                          <Typography variant="body2" className={classes.label}>
                            Contact No.
                          </Typography>
                          <Typography sx={{ mb: 2 }} variant="body1">
                            {userdatastate.graphData?.mobilePhone}
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body2" className={classes.label}>
                      Work Location
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="body1">
                      {userdatastate.graphData?.officeLocation}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" className={classes.label}>
                      Email id
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="body1">
                      {userdatastate.graphData?.mail}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className={
                  userdatastate.userData?.userBadges?.length === 0
                    ? classes.nonftfound
                    : classes.card
                }
              >
                <CardContent>
                  <Typography variant="h3">NFTs Received</Typography>
                  <Divider sx={{ mt: 2, mb: 4 }} />
                  {userdatastate.userData?.userBadges?.length === 0 ? (
                    <NoDataFound
                      icon={<SearchOffIcon />}
                      title="No NFT found"
                      subTitle="The NFTs you earn will show up here."
                    ></NoDataFound>
                  ) : (
                    <>
                      {designationBadges?.length ? (
                        <>
                          <Typography sx={{ mb: 1 }} variant="h4">
                            Designation
                          </Typography>
                          <Divider sx={{ mb: 2 }} />
                          <ProfileData
                            userBadges={designationBadges}
                            selectedBadge={selectedBadge}
                            type="Designation"
                            selectedBadgeID={selectedBadgeID}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {eventBadges?.length ? (
                        <>
                          <Typography sx={{ mb: 1 }} variant="h4">
                            Events
                          </Typography>
                          <Divider sx={{ mb: 2 }} />
                          <ProfileData
                            userBadges={eventBadges}
                            selectedBadge={selectedBadge}
                            type="Event"
                            selectedBadgeID={selectedBadgeID}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              {userdatastate.userData?.userBadges?.length === 0 ? null : (
                <BadgeSelectedDetails
                  badgeData={badgeData}
                  badgeSelectedLength={badgeData.length}
                  badgeCategory={badgeCategory}
                  parent="profile"
                ></BadgeSelectedDetails>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default ProfileImages;
