import { AppBar, Box, Button, Grid, Toolbar } from '@mui/material';
import useStyles from './FooterStyle';

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footer}>
        <Button
          variant="contained"
          target="_blank"
          href="https://cookienotice.deloitte.com"
          color="primary"
        >
          Cookies
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="optanon-toggle-display"
        >
          Cookie Settings
        </Button>
      </Box>
    </>
  );
};
