import { Dispatch, useReducer, createContext } from 'react';

export interface ISnackbarState {
  message: string;
  type: string;
}

export const initialItems: ISnackbarState = {
  message: '',
  type: 'success',
};
// Actions
export enum ActionType {
  setMessage,
}

export interface setMessage {
  type: ActionType.setMessage;
  payload: ISnackbarState;
}

export type SnackBarActions = setMessage;

//reducer
export const setMessage = (payload: ISnackbarState): setMessage => ({
  type: ActionType.setMessage,
  payload: payload,
});

export const SnackbarContext = createContext<{
  state: ISnackbarState;
  dispatch: Dispatch<SnackBarActions>;
  setToster: any;
}>({
  state: initialItems,
  dispatch: () => null,
  setToster: () => null,
});

export const manageBadgeReducer = (
  state: ISnackbarState,
  action: SnackBarActions
): ISnackbarState => {
  switch (action.type) {
    case ActionType.setMessage:
      const badgestate = {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
      };
      return badgestate;
    default:
      return state;
  }
};

export const SnackbarContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(manageBadgeReducer, initialItems);
  const setToster = (payload: ISnackbarState) => {
    dispatch(setMessage(payload));
  };
  return (
    <SnackbarContext.Provider value={{ state, dispatch, setToster }}>
      {props.children}
    </SnackbarContext.Provider>
  );
};
