import { makeStyles } from '@mui/styles';
import { FFColors } from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  nonft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchIcon: {
    color: FFColors.CardBg,
    '& .MuiSvgIcon-root': {
      width: '45.1px',
      height: '45.18px',
    },
  },
  search: {
    background: FFColors.BorderGray,
    width: '138px',
    height: '138px',
    borderRadius: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nodataPara: {
    coloe: FFColors.Black,
    opacity: '0.3',
  },
}));
