import { makeStyles } from '@mui/styles';
import { spacetheme, FFColors } from '../../theme';

export default makeStyles(() => ({
  datePickerWrapper: {
    color: FFColors.TextGray,
    marginBottom: spacetheme.spacing(2),
    '& p': {
      marginTop: spacetheme.spacing(7),
      marginBottom: spacetheme.spacing(2),
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: spacetheme.spacing(4, 7),
    },
    '& .MuiInputAdornment-root': {
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
    },
  },
}));
