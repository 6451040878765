export enum TUTORIAL_FORMS {
  // Messages
  BROADCAST = 'broadcast',
  PRIVATE = 'private',
  DATATYPE = 'datatypes',
  // Tokens
  POOL = 'pools',
  MINT = 'mint',
  TRANSFER = 'transfer',
  BURN = 'burn',
  // Contract
  DEPLOY_CONTRACT = 'deployContract',
  DEFINE_CONTRACT_INTERFACE = 'interface',
  REGISTER_CONTRACT_API = 'api',
  REGISTER_CONTRACT_API_LISTENER = 'listener',
}

export enum TUTORIAL_CATEGORIES {
  MESSAGES = 'messages',
  TOKENS = 'tokens',
  CONTRACTS = 'contracts',
}
