import { makeStyles } from '@mui/styles';
import { spacetheme } from '../../theme';

export default makeStyles(() => ({
  snackbarWrapper: {
    top: '69px',
  },
  snackbarAlert: {
    alignItems: 'center',
    padding: spacetheme.spacing(3, 7),
    borderRadius: 0,
    '& .MuiAlert-icon': {
      display: 'none',
    },
    '& .MuiAlert-message': {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      padding: 0,
      margin: 0,
    },
    '& .MuiAlert-action': {
      padding: 0,
      marginLeft: 30,
    },
  },
}));
