import { createContext, useReducer, useContext, Dispatch } from 'react';
import { SDK_PATHS } from '../constants/SDK_PATHS';
import { UseFetchAPI } from '../utils/UseFetchAPI';
import { ToastMsg, STATUS } from '../constants/constants';
import { SnackbarContext } from './SnackbarContext';
import { UserDataContext } from './UserDataContext';

// State
export interface IAssignBadgeState {
  badges: any;
  badgeslist: { newBadges: any; approvedBadges: any; rejectedBadges: any };
}

export const initialItems: IAssignBadgeState = {
  badges: [],
  badgeslist: {
    newBadges: [],
    approvedBadges: [],
    rejectedBadges: [],
  },
};
// Actions
export enum ActionType {
  badges,
}

export interface setBadges {
  type: ActionType.badges;
  payload: IAssignBadgeState;
}

export type AssignBadgeActions = setBadges;

//reducer
export const setBadges = (payload: IAssignBadgeState): setBadges => ({
  type: ActionType.badges,
  payload: payload,
});

export const assignBadgeReducer = (
  state: IAssignBadgeState,
  action: AssignBadgeActions
): IAssignBadgeState => {
  switch (action.type) {
    case ActionType.badges:
      const badgestate = {
        ...state,
        badges: action.payload.badges,
        badgeslist: action.payload.badgeslist,
      };
      return badgestate;
    default:
      return state;
  }
};
export const AssignBadgeContext = createContext<{
  state: IAssignBadgeState;
  dispatch: Dispatch<AssignBadgeActions>;
  fetchBadgesAPI: any;
  approveRejectBadgeAPI: any;
}>({
  state: initialItems,
  dispatch: () => null,
  fetchBadgesAPI: () => null,
  approveRejectBadgeAPI: () => null,
});
const fetchWrapper = UseFetchAPI();

export const AssignBadgeContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(assignBadgeReducer, initialItems);
  const { setToster } = useContext(SnackbarContext);
  const { userdatastate } = useContext(UserDataContext);

  const fetchBadgesAPI = async () => {
    try {
      const result = await fetchWrapper.get({
        url: SDK_PATHS.badges['assign'].get,
        userData: userdatastate.userData,
      });
      const payload = await badgeFilter(result);
      dispatch(setBadges(payload));
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };
  const approveRejectBadgeAPI = async (
    isApproved: boolean,
    badgeId: string,
    contractBadgeId: string,
    comment: string,
    badges: any
  ) => {
    const endpoint = isApproved
      ? SDK_PATHS.badges['assign'].bulkapprove(badgeId)
      : SDK_PATHS.badges['assign'].bulkreject(badgeId);
    const payload: any = {};
    const badgeslist = await badges.map((data: any) => ({
      assignmentId: data.assignmentId,
      id: data.id,
    }));

    payload['updateList'] = badgeslist;
    if (!isApproved) {
      payload['comment'] = comment;
    }
    try {
      await fetchWrapper.patch({
        url: endpoint,
        body: payload,
        userData: userdatastate.userData,
      });
      const toastMsg =
        badges.length > 1
          ? isApproved
            ? `${badges.length} ${ToastMsg.BULKAPPROVE}`
            : `${badges.length} ${ToastMsg.BULKREJECT}`
          : isApproved
          ? ToastMsg.APPROVED
          : ToastMsg.REJECTED;
      setToster({
        message: toastMsg,
        type: isApproved ? ToastMsg.SUCESSS : ToastMsg.ERROR,
      });
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };

  return (
    <AssignBadgeContext.Provider
      value={{ state, dispatch, fetchBadgesAPI, approveRejectBadgeAPI }}
    >
      {props.children}
    </AssignBadgeContext.Provider>
  );
};
export const badgeFilter = (result: any) => {
  const newBadgesTemp: any = [],
    approvedBadgesTemp: any = [],
    rejectedBadgesTemp: any = [];
  (result || []).forEach((badge: any) => {
    const modBadge = {
      ...badge.badge,
      badgeId: badge.badgeId,
      userCreate: badge.assignUser,
      comment: badge.comment,
      userApproved: badge.approveUser,
      assignedUser: badge.user,
      assignmentId: badge.assignmentId,
      status: badge.status,
      createdAt: badge.createdAt,
      updatedAt: badge.updatedAt,
      id: badge.id,
    };
    if (
      modBadge.status == STATUS.CREATED ||
      modBadge.status == STATUS.PENDINGAPPROVAL ||
      modBadge.status == STATUS.PROCESSING ||
      modBadge.status == STATUS.ASSIGNED
    ) {
      newBadgesTemp.push(modBadge);
    } else {
      if (badge.status == STATUS.APPROVED) {
        approvedBadgesTemp.push(modBadge);
      } else {
        rejectedBadgesTemp.push(modBadge);
      }
    }
  });
  const payload = {
    badges: result,
    badgeslist: {
      newBadges: newBadgesTemp,
      approvedBadges: approvedBadgesTemp,
      rejectedBadges: rejectedBadgesTemp,
    },
  };
  return payload;
};
