import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './BreadcrumbStyles';

export const Breadcrumb: React.FC<{
  previousScreen: any;
  currentScreenName: string;
}> = ({ previousScreen, currentScreenName }) => {
  const classes = useStyles();
  const previousScreenData = previousScreen.map((previousScreen: any) => (
    <Link
      key={previousScreen.name}
      className={classes.link}
      to={previousScreen.url}
    >
      {previousScreen.name}
    </Link>
  ));
  return (
    <>
      <Box className={classes.breadcrumbRow}>
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          [{previousScreenData},
          <Typography key={currentScreenName} color="text.primary">
            {currentScreenName}
          </Typography>
          ,]
        </Breadcrumbs>
      </Box>
    </>
  );
};
