import { Box, Typography } from '@mui/material';
import useStyles from './NoDataFoundStyles';
import React from 'react';

interface Props {
  icon: any;
  title: any;
  subTitle: any;
}
export const NoDataFound: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.nonft}>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>{props.icon}</Box>
        </Box>

        <Typography component="span" variant="h4" sx={{ mt: 3 }}>
          {props.title}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          className={classes.nodataPara}
        >
          {props.subTitle}
        </Typography>
      </Box>
    </>
  );
};
