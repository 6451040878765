import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { FF_Router } from './components/Router';
import { MessageSnackbar } from './components/Snackbar/MessageSnackbar';
import { ApplicationContext } from './contexts/ApplicationContext';
import { IApiStatus, ISelfIdentity } from './interfaces/api';
import { themeOptions } from './theme';
import { AppContextProvider } from './contexts/AppContextProvider';

export const MAX_FORM_ROWS = 10;

function App() {
  const [initialized, setInitialized] = useState(true);
  const [selfIdentity, setSelfIdentity] = useState<ISelfIdentity>();
  const [jsonPayload, setJsonPayload] = useState<object>({});
  const [payloadMissingFields, setPayloadMissingFields] =
    useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<IApiStatus>();
  const [apiResponse, setApiResponse] = useState<object>({
    type: '',
    id: '',
  });
  const [tokensDisabled, setTokensDisabled] = useState(false);
  const [blockchainPlugin, setBlockchainPlugin] = useState('');

  const theme = useMemo(() => {
    return createTheme(themeOptions);
  }, []);

  if (initialized) {
    return (
      <AppContextProvider>
        <ApplicationContext.Provider
          value={{
            selfIdentity,
            jsonPayload,
            setJsonPayload,
            payloadMissingFields,
            setPayloadMissingFields,
            apiResponse,
            setApiResponse,
            apiStatus,
            setApiStatus,
            tokensDisabled,
            blockchainPlugin,
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline>
                <FF_Router />
                <MessageSnackbar />
              </CssBaseline>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApplicationContext.Provider>
      </AppContextProvider>
    );
  } else {
    return <></>;
  }
}

export default App;
