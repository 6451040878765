import { useState } from 'react';
import useStyles from './DatePickerStyle';
import { Box, Typography, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EventDateFormater } from '../../utils/dateFormater';
import { format } from 'date-fns';

interface Props {
  title: string;
  eventDateChange: any;
}
export const DatePickerComponent: React.FC<Props> = (props) => {
  const [value, setValue] = useState<Date | null>(null);
  const handleDateChange = async (newValue: any) => {
    const newDate: any = format(new Date(newValue), 'MM/dd/yyyy');
    setValue(newValue);
    props.eventDateChange(newDate);
  };
  const classes = useStyles();
  return (
    <Box className={classes.datePickerWrapper}>
      <Typography variant="body2">{props.title}</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};
