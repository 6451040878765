import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme } from '../../../theme';

export default makeStyles(() => ({
  main: {
    maxWidth: '1376px',
    margin: '0 auto',
    width: '100%',
  },
  firstRow: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: 'flex-end',
    width: `100%`,
    padding: spacetheme.spacing(10, 0),
  },
  nftImage: {
    width: '32px',
    height: '32px',
  },
  secondRow: {
    display: `flex`,
    justifyContent: `space-between`,
    padding: spacetheme.spacing(10, 0),
  },

  breadcrumbRow: {
    display: `flex`,
    justifyContent: `flex-start`,
    alignItems: `center`,
  },

  backButton: {
    marginRight: `25px`,
  },

  secondRow_innerDiv: {
    display: `flex`,
    flexDirection: `column`,
    width: `48%`,
  },

  paddingDiv: {
    paddingLeft: `24px`,
    paddingRight: `24px`,
  },

  marginDiv: {
    margin: `20px 40px`,
  },

  seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    marginLeft: `10px`,
  },

  approvedRejectedText: {
    fontWeight: `bold`,
  },
  pendingBadgeText: {
    fontWeight: `bold`,
    marginTop: '40px',
    marginBottom: '40px',
  },

  secondRow_seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    fontSize: `small`,
    marginRight: `30px`,
  },

  flex_grid: {
    display: `flex`,
    overflowX: `auto`,
    position: `relative`,
    WebkitAppearance: `none`,
    '&::-webkit-scrollbar': {
      display: `none`,
    },
  },

  card: {
    flexShrink: `0`,
    flexBasis: `40%`,
    paddingRight: `10px`,
  },

  thirdRow: {
    display: `flex`,
    justifyContent: `space-between`,
    flexDirection: `column`,
  },

  thirdRow_seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    fontSize: `small`,
    marginRight: `30px`,
  },

  createnewbadge: {
    padding: `8px 16px`,
    backgroundColor: FFColors.Black,
    borderRadius: `4px`,
    color: FFColors.White,
    fontSize: `16px`,
  },
  importExcelBtn: {
    '& .MuiButton-root': {
      height: '40px',
    },
  },
}));
