import { makeStyles } from '@mui/styles';
import { spacetheme, FFColors } from '../../theme';

export default makeStyles(() => ({
  stepDivider: {
    borderTop: '4px solid',
    borderColor: FFColors.BorderGray,
    marginTop: spacetheme.spacing(3),
    '&.active': {
      borderColor: FFColors.Black,
    },
  },
}));
