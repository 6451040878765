import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import signinBg from '../../../assets/signinbg.png';

import { FFColors, FONT_SIZE, spacetheme } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  signInWrapper: {
    background: FFColors.White,
    height: '100%',
  },
  signInContainer: {
    maxWidth: '1328px',
    margin: '0 auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      background: '#fff',
    },
  },
  deskImgWrapper: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileImgWrapper: {
    display: 'none',
    background: `url(${signinBg}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  signInImg: {
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  titleWrapper: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      position: 'absolute',
    },
    '& h1': {
      fontSize: FONT_SIZE[56],
      fontWeight: 600,
      lineHeight: '72px',
      marginBottom: spacetheme.spacing(14),
      [theme.breakpoints.down('md')]: {
        fontWeight: 700,
        fontSize: FONT_SIZE[24],
        lineHeight: '36px',
        marginBottom: spacetheme.spacing(9),
      },
    },
  },
}));
