import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import Logo from '../../assets/deloitte-logo.png';
import useStyles from './LogoStyle';

export const MenuLogo: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.logoSection}>
      <img className={classes.logoimg} src={Logo} alt="Deloitte" />
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography variant="body1">NFT Factory</Typography>
    </Box>
  );
};
