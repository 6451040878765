import { makeStyles } from '@mui/styles';
import {
  FFColors,
  FONT_SIZE,
  spacetheme,
  BADGELISTVIEW_BOX_SHADOW,
} from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
  },
  tabHeaderSec: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacetheme.spacing(14),
  },
  selectBoxSection: {
    width: '315px',
    [theme.breakpoints.down('md')]: {
      marginBottom: spacetheme.spacing(7),
      width: '100%',
    },
  },
  selectSection: {
    width: 300,
    maxWidth: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  tabBoxTitle: {
    marginTop: spacetheme.spacing(3),
    textTransform: 'none',
  },
  verticalDivider: {
    position: 'absolute',
    right: 0,
    height: spacetheme.spacing(9),
  },
  tabBoxSec: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: spacetheme.spacing(4, 9, 1),
    textTransform: 'none',
  },
  countWeight: {
    fontWeight: 700,
    fontSize: FONT_SIZE[20],
  },
  typographyColor: {
    color: FFColors.Gray,
  },
  tabHeaderTitle: {
    fontWeight: `400`,
    fontSize: FONT_SIZE[12],
    color: FFColors.Black,
  },
  pending: {
    backgroundColor: FFColors.White,
    boxShadow: BADGELISTVIEW_BOX_SHADOW,
    '& .MuiTabs-indicator': {
      backgroundColor: FFColors.Orange,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tabpanelsection: {
    '& .MuiBox-root': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    // '& .MuiDataGrid-iconButtonContainer': {
    //   position: 'relative',
    //   right: '18px',
    //   visibility: 'hidden',
    // },
  },
  rejected: {
    backgroundColor: FFColors.White,
    boxShadow: BADGELISTVIEW_BOX_SHADOW,
    '& .MuiTabs-indicator': {
      backgroundColor: FFColors.Error,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  approved: {
    backgroundColor: FFColors.White,
    boxShadow: BADGELISTVIEW_BOX_SHADOW,
    '& .MuiTabs-indicator': {
      backgroundColor: FFColors.Success,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  tabAndSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'baseline',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tabBox: {
    color: FFColors.Black,
    fontSize: FONT_SIZE[16],
    padding: spacetheme.spacing(2, 7),
    '&.Mui-selected': {
      backgroundColor: FFColors.Black,
      color: FFColors.White,
      '& .verticalDivider': {
        border: FFColors.Black,
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: spacetheme.spacing(2, 6),
    },
  },
  noBadges: {
    display: 'flex',
    height: '402px',
    width: '100%',
    backgroundColor: FFColors.LightGrey,
    alignContent: 'center',
    justifyContent: 'space-around',
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${FFColors.CardBg}`,
  },
  badgedetailsGridWrapper: {
    paddingTop: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      marginTop: '-15px',
    },
  },
}));
