import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme, FONT_SIZE } from '../../theme';
export default makeStyles(() => ({
  breadcrumbRow: {
    display: `flex`,
    justifyContent: `flex-start`,
    alignItems: `center`,
  },
  link: {
    color: FFColors.Green,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
