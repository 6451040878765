import { makeStyles } from '@mui/styles';
import { FFColors } from '../../theme';

export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  loaderColor: { color: FFColors.Green },
}));
