import { Box, Grid } from '@mui/material';
import useStyles from './Styles';
import ProfileImages from './ProfileImages';
export const Profile: () => JSX.Element = () => {
  const classes = useStyles();
  return (
    <Box className={classes.profileMain}>
      <Grid className={classes.root}>
        <ProfileImages />
      </Grid>
    </Box>
  );
};
