import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

import { Button } from '@mui/material';

function handleLogin(instance: any) {
  instance.loginRedirect(loginRequest).catch((e: any) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      component="label"
      size="large"
      color="secondary"
      onClick={() => handleLogin(instance)}
    >
      Sign in
    </Button>
  );
};
