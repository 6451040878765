// TODO: Margins should be fetched from theme
import { makeStyles } from '@mui/styles';
import { FFColors, FONT_SIZE, spacetheme } from '../../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  profileMain: { background: FFColors.Black },
  root: {
    maxWidth: '1328px',
    margin: '0 auto',
    width: '100%',
  },
  coverPic: {
    marginTop: 30,
    width: '100%',
    height: 350,
    objectFit: 'cover',
  },
  card: {
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${FFColors.LightGrey}`,
  },
  badgeCard: {
    '&:hover': {
      border: `1px solid ${FFColors.BorderGray}`,
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: '#fff',
    },
  },
  activeBadge: {
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${FFColors.ActiveGreen}`,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '6px',
  },
  date: {
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.between('md', 'lg')]: {
      flexDirection: 'column',
      textAlign: 'left',
    },
  },
  dividerColor: {
    borderColor: FFColors.BorderGray,
  },
  boxMargin: { marginLeft: spacetheme.spacing(8) },
  profileImageMain: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      margin: '24px',
    },
    '@media (max-width:1400px)': {
      margin: '24px',
    },
  },
  image: {
    width: '121px',
    height: '140px',
    objectFit: 'cover',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  loaderColor: { color: FFColors.Green },
  profileImageBackground: {
    background: FFColors.Black,
    height: '50px',
    [theme.breakpoints.down('md')]: {
      height: '0px',
    },
  },
  profileImageContainers: {
    background: FFColors.Black,
    color: FFColors.White,
    paddingBottom: '90px',
    '& h1': {
      marginBottom: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '26px',
      },
    },
  },
  divider: { border: `1px solid ${FFColors.TextGray}` },
  profileImageGrid: { position: 'absolute', paddingTop: '24px' },
  firstContainer: { display: 'flex' },
  avatar: { width: '121px', height: '140px' },
  welcome: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: '16px',
  },
  welcomeText: {
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
  },
  basicUserData: { display: 'flex', justifyContent: 'space-between' },
  label: {
    color: FFColors.TextGray,
  },
  nonftfound: {
    height: '402px',
    background: FFColors.LightGrey,
    borderRadius: 0,
    boxShadow: 'none',
  },
  userPic: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: 310,
    left: 30,
  },
  headerProfile: {
    padding: spacetheme.spacing(1),
    marginLeft: spacetheme.spacing(11),
    fontWeight: 'bold',
    fontSize: FONT_SIZE[14],
  },

  badgePic: {
    width: '80px',
    height: '80px',
    objectFit: 'contain',
  },
  infoBadge: {
    margin: '15%',
    marginTop: '7%',
  },
  badgeName: {
    color: FFColors.TextGray,
  },
  dateText: {
    marginLeft: '4px',
  },
}));
