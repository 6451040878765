import React, { useReducer, Dispatch, createContext, useContext } from 'react';
import { SDK_PATHS } from '../constants/SDK_PATHS';
import { UseFetchAPI } from '../utils/UseFetchAPI';
import { ToastMsg, STATUS } from '../constants/constants';
import { SnackbarContext } from './SnackbarContext';
import { UserDataContext } from './UserDataContext';
export interface IManageBadgeState {
  badges: any;
  badgeslist: { newBadges: any; approvedBadges: any; rejectedBadges: any };
}

export const initialItems: IManageBadgeState = {
  badges: [],
  badgeslist: {
    newBadges: [],
    approvedBadges: [],
    rejectedBadges: [],
  },
};
// Actions
export enum ActionType {
  badges,
}

export interface setBadges {
  type: ActionType.badges;
  payload: IManageBadgeState;
}

export type ManageBadgeActions = setBadges;

//reducer
export const setBadges = (payload: IManageBadgeState): setBadges => ({
  type: ActionType.badges,
  payload: payload,
});
export const ManageBadgeContext = createContext<{
  state: IManageBadgeState;
  dispatch: Dispatch<ManageBadgeActions>;
  fetchBadgesAPI: any;
  approveRejectBadgeAPI: any;
  createBadgeAPI: any;
}>({
  state: initialItems,
  dispatch: () => null,
  fetchBadgesAPI: () => null,
  approveRejectBadgeAPI: () => null,
  createBadgeAPI: () => null,
});

export const manageBadgeReducer = (
  state: IManageBadgeState,
  action: ManageBadgeActions
): IManageBadgeState => {
  switch (action.type) {
    case ActionType.badges:
      const badgestate = {
        ...state,
        badges: action.payload.badges,
        badgeslist: action.payload.badgeslist,
      };
      return badgestate;
    default:
      return state;
  }
};

export const ManageBadgeContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(manageBadgeReducer, initialItems);
  const { setToster } = useContext(SnackbarContext);
  const { userdatastate } = useContext(UserDataContext);
  const fetchBadgesAPI = async () => {
    try {
      const result = await fetchWrapper.get({
        url: SDK_PATHS.badges['manage'].get,
        userData: userdatastate.userData,
      });
      const payload = await badgeFilter(result);
      dispatch(setBadges(payload));
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };

  const approveRejectBadgeAPI = async (
    isApproved: boolean,
    badgeId: string,
    contractBadgeId: any,
    badges: any,
    comment?: string
  ) => {
    const endpoint = isApproved
      ? SDK_PATHS.badges['manage'].bulkapprove(badgeId)
      : SDK_PATHS.badges['manage'].bulkreject(badgeId);
    const payload: any = {};
    const badgeslist = await badges.map((data: any) => ({
      contractBadgeId: data.contractBadgeId,
      id: data.id,
    }));

    payload['updateList'] = badgeslist;
    if (!isApproved) {
      payload['comment'] = comment;
    }
    try {
      await fetchWrapper.patch({
        url: endpoint,
        body: payload,
        userData: userdatastate.userData,
      });
      const toastMsg =
        badges.length > 1
          ? isApproved
            ? `${badges.length} ${ToastMsg.BULKAPPROVE}`
            : `${badges.length} ${ToastMsg.BULKREJECT}`
          : isApproved
          ? ToastMsg.APPROVED
          : ToastMsg.REJECTED;
      setToster({
        message: toastMsg,
        type: isApproved ? ToastMsg.SUCESSS : ToastMsg.ERROR,
      });
      const tempBadges = badges.filter((badge: any) => badge.id !== badgeId);
      return tempBadges;
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };
  const createBadgeAPI = async (createBadgeForm: any) => {
    const formData = new FormData();
    formData.append('location', createBadgeForm?.location);
    formData.append('lineOfBusiness', createBadgeForm?.lineOfBusiness);
    formData.append('offeringPortfolio', createBadgeForm?.offeringPortfolio);
    formData.append('offering', createBadgeForm?.offering);
    formData.append('talentGroup', createBadgeForm?.talentGroup);
    formData.append('type', createBadgeForm?.type);
    formData.append('file', createBadgeForm?.file);
    formData.append('createdBy', createBadgeForm?.createdBy);
    formData.append(
      'typeSpecificData',
      JSON.stringify(createBadgeForm?.typeSpecificData)
    );
    formData.append('name', createBadgeForm?.name);
    formData.append('description', createBadgeForm?.description);
    formData.append('rewards', createBadgeForm?.rewards);
    try {
      const res = await fetchWrapper.post({
        url: SDK_PATHS.badges['manage'].post,
        body: formData,
        isFormData: true,
        userData: userdatastate.userData,
      });
      setToster({ message: ToastMsg.CREATEBADGE, type: ToastMsg.SUCESSS });
      return res;
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };

  return (
    <ManageBadgeContext.Provider
      value={{
        state,
        dispatch,
        fetchBadgesAPI,
        approveRejectBadgeAPI,
        createBadgeAPI,
      }}
    >
      {props.children}
    </ManageBadgeContext.Provider>
  );
};

const fetchWrapper = UseFetchAPI();

export const badgeFilter = (result: any) => {
  const newBadgesTemp: any = [],
    approvedBadgesTemp: any = [],
    rejectedBadgesTemp: any = [];
  (result || []).forEach((badge: any) => {
    if (
      badge.status == STATUS.CREATED ||
      badge.status == STATUS.PENDINGAPPROVAL ||
      badge.status == STATUS.PROCESSING
    ) {
      newBadgesTemp.push(badge);
    } else {
      if (badge.status == STATUS.APPROVED) {
        approvedBadgesTemp.push(badge);
      } else {
        rejectedBadgesTemp.push(badge);
      }
    }
  });
  const payload = {
    badges: result,
    badgeslist: {
      newBadges: newBadgesTemp,
      approvedBadges: approvedBadgesTemp,
      rejectedBadges: rejectedBadgesTemp,
    },
  };
  return payload;
};
