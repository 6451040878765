import { makeStyles } from '@mui/styles';
import { FFColors, FONT_SIZE, spacetheme } from '../../../theme';

export default makeStyles(() => ({
  main: {
    maxWidth: '1376px',
    margin: '0 auto',
    width: '100%',
  },
  nftImage: {
    width: '32px',
    height: '32px',
  },
  info: {
    background: FFColors.Black,
    color: FFColors.White,
    width: spacetheme.spacing(9),
    borderRadius: spacetheme.spacing(19),
    justifyContent: 'center',
    display: 'flex',
    fontFamily: 'dds-icon',
    fontWeight: 400,
    fontSize: FONT_SIZE[14],
    margin: spacetheme.spacing(2),
  },
  firstRow: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: 'flex-end',
    width: `100%`,
    padding: spacetheme.spacing(10, 0),
  },
  secondRow: {
    display: `flex`,
    justifyContent: `space-between`,
    padding: spacetheme.spacing(10),
  },

  breadcrumbRow: {
    display: `flex`,
    justifyContent: `flex-start`,
    alignItems: `center`,
  },
  iconButtonContainer: {
    position: 'relative',
    marginLeft: '10px',
    cursor: 'pointer',
    top: '2px',
  },
  secondRow_innerDiv: {
    display: `flex`,
    flexDirection: `column`,
    width: `48%`,
  },

  paddingDiv: {
    paddingLeft: spacetheme.spacing(10),
    paddingRight: spacetheme.spacing(10),
  },

  marginDiv: {
    margin: spacetheme.spacing(9, 14),
  },

  seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    marginLeft: spacetheme.spacing(5),
  },

  approvedRejectedText: {
    fontWeight: `bold`,
  },
  pendingBadgeText: {
    fontWeight: `bold`,
    marginTop: spacetheme.spacing(14),
    marginBottom: spacetheme.spacing(14),
  },

  secondRow_seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    fontSize: `small`,
    marginRight: `30px`,
  },

  flex_grid: {
    display: `flex`,
    overflowX: `auto`,
    position: `relative`,
    WebkitAppearance: `none`,
    '&::-webkit-scrollbar': {
      display: `none`,
    },
  },

  card: {
    flexShrink: `0`,
    flexBasis: `40%`,
    paddingRight: spacetheme.spacing(5),
  },

  thirdRow: {
    display: `flex`,
    justifyContent: `space-between`,
    flexDirection: `column`,
  },

  thirdRow_seeAllText: {
    textDecoration: `underline`,
    cursor: `pointer`,
    fontSize: `small`,
    marginRight: `30px`,
  },

  createnewbadge: {
    padding: spacetheme.spacing(4, 8),
    backgroundColor: FFColors.Black,
    borderRadius: spacetheme.spacing(2),
    color: FFColors.White,
    fontSize: FONT_SIZE[16],
  },
  createNftBtn: {
    '& .MuiButton-root': {
      height: '40px',
    },
  },
}));
