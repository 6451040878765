interface formData {
  method: string;
  headers?: any;
  body?: any;
}

const UseFetchAPI = () => {
  const postUrlPrefix = window.location.protocol.startsWith('https')
    ? `https://${window.location.hostname}`
    : '';
  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    patch: request('PATCH'),
  };

  function request(method: string, param?: string) {
    return (data: any) => {
      const { url, body, isFormData, userData } = data;
      const requestOptions: formData = {
        method,
        headers: {
          'nft-user': userData?.email,
        },
      };
      if (!isFormData) {
        requestOptions['headers'] = {
          ...requestOptions['headers'],
          'Content-Type': 'application/json',
        };
      }
      if (body) {
        requestOptions['body'] = isFormData ? body : JSON.stringify(body);
      }
      return fetch(`${postUrlPrefix}${url}`, requestOptions).then(
        handleResponse
      );
    };
  }

  function handleResponse(response: any) {
    return response.text().then((text: any) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }
};
export { UseFetchAPI };
