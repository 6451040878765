import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { FFColors } from '../../theme';
export default makeStyles((theme: Theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: FFColors.Black,
    paddingRight: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
