import { makeStyles } from '@mui/styles';
import { spacetheme, FFColors } from '../../../theme';

export default makeStyles(() => ({
  main: {
    maxWidth: '1328px',
    width: '100%',
    padding: spacetheme.spacing(10),
    margin: '0 auto',
    marginBottom: '50px',
  },
  createNewBadgeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacetheme.spacing(7),
    flexDirection: 'column',
  },
  asterisk: { color: FFColors.Red },
  category: {
    minHeight: '153px',
    border: `1px solid ${FFColors.BorderGray}`,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: spacetheme.spacing(10),
  },

  details: {
    border: `1px solid ${FFColors.BorderGray}`,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: spacetheme.spacing(10),
  },

  defaultMessage: {
    alignItems: 'center',
    minHeight: '153px',
    display: 'flex',
    justifyContent: 'center',
    background: FFColors.CardBg,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: spacetheme.spacing(18),
  },
  nftFormWrapper: {
    '& p': {
      marginTop: spacetheme.spacing(11),
      marginBottom: spacetheme.spacing(2),
    },
    '& .MuiFormControl-root': {
      width: '100%',
      '& input.MuiOutlinedInput-input': {
        padding: spacetheme.spacing(4, 7),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
      },
    },
  },
}));
