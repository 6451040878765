import { makeStyles } from '@mui/styles';
import { FFColors } from '../../theme';
export default makeStyles(() => ({
  main: {
    height: 400,
    width: '100%',
    backgroundColor: FFColors.White,
    '& .MuiDataGrid-root': {
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
    },
    '& .MuiDataGrid-main': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        borderRadius: 0,
      },
    },
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'right',
    },
    '& .MuiDataGrid-selectedRowCount': {
      display: 'none',
    },
  },
}));
