import { AssignBadgeContextProvider } from './AssignBadgeContext';
import { AssignmentValidateContextProvider } from './AssignmentValidateContext';
import { SnackbarContextProvider } from './SnackbarContext';
import { ManageBadgeContextProvider } from './ManageBadgeContext';
import { UserDataContextProvider } from './UserDataContext';
import { combineComponents } from '../utils/combineComponents';
import { LoaderContextProvider } from './LoaderContext';

const providers = [
  SnackbarContextProvider,
  LoaderContextProvider,
  UserDataContextProvider,
  ManageBadgeContextProvider,
  AssignBadgeContextProvider,
  AssignmentValidateContextProvider,
];
export const AppContextProvider = combineComponents(...providers);
