import { useState } from 'react';
import useStyles from './SelectBoxStyle';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
interface Props {
  title: string;
  list: any;
  selectBoxChange: any;
  placeholder: string;
  defaultValue?: string;
}
export const SelectBox: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.defaultValue || '');
  const selectListChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    props.selectBoxChange(event.target.value as string);
  };
  const classes = useStyles();
  const iconComponent = () => {
    return <KeyboardArrowDownIcon />;
  };
  return (
    <Box>
      <Typography
        component="span"
        variant="body2"
        className={classes.selectBoxTitle}
      >
        {props.title}
      </Typography>
      <FormControl sx={{ width: '100%' }} size="small">
        <Select
          value={value}
          IconComponent={iconComponent}
          onChange={selectListChange}
          displayEmpty
          className={classes.selectBoxRoot}
        >
          <MenuItem disabled value="">
            <span className={classes.selectBoxPlaceHolder}>
              {props.placeholder}
            </span>
          </MenuItem>
          {(props.list || []).map((data: string, index: number) => (
            <MenuItem key={index} value={data}>
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
