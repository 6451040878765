import { Grid, Box, Typography } from '@mui/material';
import { SignInButton } from '../../../components/SignInButton';
import signinBg from '../../../assets/signinbg.png';
import useStyles from './SignInStyle';

export const SignIn: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.signInWrapper}>
      <Grid
        container
        className={classes.signInContainer}
        justifyContent={'center'}
        alignItems="center"
        direction="row"
      >
        <Grid item md={8} className={classes.deskImgWrapper}>
          <img src={signinBg} className={classes.signInImg} alt="Sign In" />
        </Grid>
        <Grid item md={8} className={classes.mobileImgWrapper}></Grid>
        <Grid item md={4} className={classes.titleWrapper}>
          <Box>
            <Typography variant="h1">
              Now own your <br /> NFTs
            </Typography>
            <SignInButton />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
