import { Box } from '@mui/material';
import { EventLevels } from '../../constants/constants';
import { SelectBox } from '../SelectBox/SelectBox';
import { DatePickerComponent } from '../DatePicker/DatePicker';
export const EventBadgeForm: React.FC<{
  badgeLevelChange: any;
  eventDateChange: any;
}> = ({ badgeLevelChange, eventDateChange }) => {
  return (
    <Box sx={{ paddingBottom: '20px' }}>
      <SelectBox
        title={'Choose Participation Type'}
        selectBoxChange={badgeLevelChange}
        list={EventLevels}
        placeholder={'Select from list'}
      />
      <DatePickerComponent
        title={'Event Date'}
        eventDateChange={eventDateChange}
      />
    </Box>
  );
};
