// TODO: Few styles should be fetched from themes
// TODO: Color should be imported from theme
// TODO: font size should be imported from theme
import { makeStyles } from '@mui/styles';
import { FFColors, FONT_SIZE, spacetheme } from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  badgeDetailCardWrapper: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    alignItems: 'flex-end',
  },
  dummyCardSection: {
    width: '95%',
    height: '100%',
    backgroundColor: FFColors.White,
    position: 'absolute',
    top: '0',
    border: `1px solid ${FFColors.CardBg}`,
  },
  dummyCardSection1: {
    top: '8px',
    right: '-11px',
    left: 'unset',
  },
  dummyCardSectiontwo: {
    width: '95%',
    height: '100%',
    backgroundColor: FFColors.White,
    position: 'absolute',
    top: '0',
    right: '0',
    border: `1px solid ${FFColors.CardBg}`,
  },
  dummyCard1: {
    top: '8px',
    left: '11px',
  },
  dummyCard2: {
    top: '16px',
    left: '22px',
  },
  font: {
    fontWeight: '500',
  },
  selectedCount: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #ED8B00',
    marginBottom: '4px',
    display: 'inline-block',
    position: 'absolute',
    top: '-33px',
    left: '17px',
    padding: '3px 8px 5px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  secondCard: {
    width: '95%',
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${FFColors.CardBg}`,
    marginTop: '12px',
    '&.MuiOutlinedInput-root': {
      padding: spacetheme.spacing(0),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  secondCardOne: {
    marginTop: '20px',
  },
  secondCardTwo: {
    marginTop: '28px',
  },
  typographyColor: {
    color: FFColors.Grey,
  },
  commentsRequired: {
    paddingBottom: spacetheme.spacing(5),
  },
  badgeDetailCard: {
    width: '95%',
    backgroundColor: FFColors.White,
    position: 'relative',
    border: `1px solid ${FFColors.CardBg}`,
    padding: spacetheme.spacing(8),
    color: FFColors.Black,

    '@media (max-width:768px)': {
      width: '100%',
    },
  },
  badgeDetailCardFullWidth: {
    width: '100%',
  },
  badgeImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '80px',
      height: '80px',
      objectFit: 'contain',
    },
  },
  level: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  header: {
    fontSize: FONT_SIZE[16],
    fontWeight: '600',
  },
  commentsBox: {
    display: 'flex',
  },
  commentsBoxContent: {
    marginLeft: spacetheme.spacing(4),
    marginTop: spacetheme.spacing(1),
  },
  asterisk: { color: FFColors.Red },
  approvedRejectedButton: {
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: FFColors.Grey,
      color: FFColors.White,
    },
    '&:hover': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  completeDivider: {
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  emailField: {
    wordWrap: 'break-word',
  },
  displayFieldHorizontal: {
    display: `flex`,
    justifyContent: `space-between`,
    width: '100%',
  },
}));
