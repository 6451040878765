import { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Divider,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './CreateBadgeStyles';
import { UserDataContext } from '../../../contexts/UserDataContext';
import { EventBadgeForm } from '../../../components/BadgeTypeForm/EventBadge';
import {
  BADGE_CATEGORY,
  DesignationLevels,
} from '../../../constants/constants';
import { SelectBox } from '../../../components/SelectBox/SelectBox';
import { StepHeader } from '../../../components/StepHeader/StepHeader';
import { ManageBadgeContext } from '../../../contexts/ManageBadgeContext';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { DragAndDrop } from '../../../components/DragAndDrop/DragAndDrop';
import { LoaderContext } from '../../../contexts/LoaderContext';

export const CreateBadge: React.FC = () => {
  const navigate = useNavigate();
  const { userdatastate } = useContext(UserDataContext);
  const { createBadgeAPI } = useContext(ManageBadgeContext);
  const { dispatch, setLoading } = useContext(LoaderContext);
  const [badgeCategory, setBadgeCategory] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [createBadgeForm, setBadgeForm] = useState({
    location: userdatastate.currentOrganisation?.location,
    lineOfBusiness: userdatastate.currentOrganisation?.lineOfBusiness,
    offeringPortfolio: userdatastate.currentOrganisation?.offeringPortfolio,
    offering: userdatastate.currentOrganisation?.offering,
    talentGroup: userdatastate.currentOrganisation?.talentGroup,
    type: '',
    file: '',
    createdBy: userdatastate.userData?.id,
    typeSpecificData: {
      level: '',
      eventStartDate: '',
    },
    name: '',
    description: '',
    rewards: '0',
  });
  useEffect(() => {
    handleChange('createdBy', userdatastate.userData?.id);
  }, [userdatastate.userData]);

  const badgeCategoryChange = (value: string) => {
    setBadgeCategory(value);
    handleChange('type', value);
  };

  const badgeLevelChange = (value: string) => {
    handleChange('typeSpecificData', {
      ...createBadgeForm.typeSpecificData,
      level: value,
    });
  };
  const eventDateChange = (value: string) => {
    handleChange('typeSpecificData', {
      ...createBadgeForm.typeSpecificData,
      eventStartDate: value,
    });
  };

  const resetForm = () => {
    setBadgeForm({
      ...createBadgeForm,
      file: '',
      name: '',
      description: '',
      rewards: '',
    });
  };
  const fileProcessing = (event: any, file: any) => {
    setBadgeForm({ ...createBadgeForm, ['file']: file });
    setImageUrl(URL.createObjectURL(file));
    setIsFileUploaded(true);
    event.target.value = null;
    checkDisabled();
  };

  const fileUploadClose = () => {
    setIsFileUploaded(false);
  };
  const handleChange = (name: string, value: any) => {
    setBadgeForm({ ...createBadgeForm, [name]: value });
    checkDisabled();
  };
  const classes = useStyles();
  const submitForm = async () => {
    dispatch(setLoading(true));
    const res = await createBadgeAPI(createBadgeForm);
    dispatch(setLoading(false));
    if (res) {
      navigate('/manage-badges', { replace: true });
    }
  };

  const checkDisabled = () => {
    if (
      createBadgeForm.type &&
      createBadgeForm.file &&
      createBadgeForm.createdBy &&
      createBadgeForm.rewards &&
      createBadgeForm.typeSpecificData &&
      createBadgeForm.typeSpecificData.level &&
      createBadgeForm.name
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Container className={classes.main}>
      <Breadcrumb
        previousScreen={[{ name: 'Manage NFT', url: '/manage-badges' }]}
        currentScreenName="Create a NFT"
      />
      <Box>
        <Typography variant="h2" sx={{ mt: 4 }}>
          Create a NFT
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The NFT creation form will be populated based on the category
          selection
        </Typography>
      </Box>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <StepHeader title={'Step 1'} active={true} />
              <Card className={classes.category}>
                <CardContent>
                  <Typography variant="h3" sx={{ mb: 2 }}>
                    Category
                  </Typography>

                  <SelectBox
                    title={'Choose NFT Category'}
                    selectBoxChange={badgeCategoryChange}
                    list={BADGE_CATEGORY}
                    placeholder={'Select from list'}
                  />
                </CardContent>
              </Card>
            </Grid>
            {badgeCategory ? (
              <>
                <Grid item xs={12} md={8}>
                  <StepHeader title={'Step 2'} active={false} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Card className={classes.category}>
                        <CardContent>
                          <Typography variant="h3" sx={{ mb: 2 }}>
                            Preliminary Details
                          </Typography>
                          {badgeCategory == 'Designation' ? (
                            <SelectBox
                              title={'Choose Level'}
                              selectBoxChange={badgeLevelChange}
                              list={DesignationLevels}
                              placeholder={'Select from list'}
                            />
                          ) : (
                            <EventBadgeForm
                              badgeLevelChange={badgeLevelChange}
                              eventDateChange={eventDateChange}
                            ></EventBadgeForm>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" className={classes.details}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="h3">NFT Details</Typography>
                          <Box
                            sx={{
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <DragAndDrop
                              fileProcessing={fileProcessing}
                              fileUploadClose={fileUploadClose}
                              isFileUploaded={isFileUploaded}
                              fileName={imageUrl}
                              acceptFiles="image/*"
                              buttonTitle={
                                isFileUploaded
                                  ? 'Re-Upload NFT image'
                                  : 'Upload NFT image'
                              }
                              AcceptsImageFormate={true}
                            ></DragAndDrop>
                          </Box>
                          <Box className={classes.nftFormWrapper}>
                            <Typography variant="body2">
                              NFT Name
                              <sup className={classes.asterisk}>*</sup>
                            </Typography>
                            <TextField
                              value={createBadgeForm.name}
                              onChange={(e) =>
                                handleChange('name', e.target.value)
                              }
                            />
                            <Typography variant="body2">Description</Typography>
                            <TextField
                              value={createBadgeForm.description}
                              minRows={3}
                              multiline={true}
                              onChange={(e) =>
                                handleChange('description', e.target.value)
                              }
                            />
                            {/* <Typography variant="body2">
                                Number of Green Dots
                                <sup className={classes.asterisk}>*</sup>
                              </Typography> */}
                            {/* <TextField
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      GD
                                    </InputAdornment>
                                  ),
                                }}
                                value={createBadgeForm.rewards}
                                onChange={(e) =>
                                  handleChange('rewards', e.target.value)
                                }
                              /> */}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={8}>
                <Card className={classes.defaultMessage}>
                  <CardContent>
                    <Typography variant="body1">
                      Form details to be populated based on the category
                      selection
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ mt: 5 }}></Divider>
          {badgeCategory ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                  }}
                >
                  <Button variant="outlined" onClick={resetForm}>
                    Discard
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitDisabled}
                    onClick={submitForm}
                  >
                    Submit For Approval
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
};
