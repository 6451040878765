export const DesignationLevels = ['Consultant', 'Senior Consultant', 'Manager'];
export const EventLevels = ['Organizer', 'Attendee', 'Winner'];
export const Sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const STATUS = {
  CREATED: 'Created',
  PROCESSING: 'Processing',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDINGAPPROVAL: 'Pending Approval',
  ASSIGNED: 'Assigned',
};
export const ADMIN_ROLES = [
  'tthangadurai@deloitte.com',
  'shupramanick@deloitte.com',
  'sribgoli@deloitte.com',
];
export const ToastMsg = {
  BULKAPPROVE: 'Badges approval initiated successfully.',
  BULKREJECT: 'Badges rejected and comment submitted.',
  REJECTED: 'Badge rejected and comment submitted.',
  APPROVED: 'Badge approval initiated successfully.',
  CREATEBADGE:
    'Badge creation initiated successfully and submitted for review.',
  SUCESSS: 'success',
  ERROR: 'error',
  BULKASSIGN: 'badges have been successfully assigned.',
};
export const BADGE_CATEGORY = ['Event', 'Designation'];
export const EXCEL_DATE_ERROR_MESSAGE =
  'Invalid Date format. kindly enter Event Start Date in MM/DD/YYYY format.';
