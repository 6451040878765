import useStyles from './StepHeaderStyle';
import { Box, Typography, Divider } from '@mui/material';
interface Props {
  title: string;
  active: boolean;
}
export const StepHeader: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        {props.title}
      </Typography>
      <Divider
        className={classes.stepDivider + `${props.active ? ' active' : ''}`}
      />
    </Box>
  );
};
