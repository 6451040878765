import React, { createContext, Dispatch, useReducer } from 'react';
import { FFCircularProgress } from '../components/Loaders/FFCircularProgress';

export interface ILoaderState {
  loading: boolean;
}

export const initialState: ILoaderState = {
  loading: false,
};

// Actions
export enum ActionType {
  loading,
}

export interface setLoading {
  type: ActionType.loading;
  payload: boolean;
}

export type LoaderActions = setLoading;

//reducer
export const setLoading = (payload: boolean): setLoading => ({
  type: ActionType.loading,
  payload: payload,
});

export const loaderReducer = (
  state: ILoaderState,
  action: LoaderActions
): ILoaderState => {
  switch (action.type) {
    case ActionType.loading:
      return {
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const LoaderContext = createContext<{
  state: ILoaderState;
  dispatch: Dispatch<LoaderActions>;
  setLoading: any;
}>({
  state: initialState,
  dispatch: () => undefined,
  setLoading: () => null,
});

export const LoaderContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);

  return (
    <LoaderContext.Provider value={{ state, dispatch, setLoading }}>
      {state.loading && <FFCircularProgress />}
      {props.children}
    </LoaderContext.Provider>
  );
};
