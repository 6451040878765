import {
  useReducer,
  Dispatch,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { SDK_PATHS } from '../constants/SDK_PATHS';
import { UseFetchAPI } from '../utils/UseFetchAPI';
import { ToastMsg } from '../constants/constants';
import { SnackbarContext } from './SnackbarContext';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../authConfig';
import { useMsal } from '@azure/msal-react';

interface Organisation {
  id: string;
  name: string;
  walletId: string;
  location: string;
  createdAt: string;
  updatedAt: string;
  UserOrganisations: {
    createdAt: string;
    updatedAt: string;
    userId: string;
    organisationId: string;
  };
}
interface Role {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  UserRoles: {
    createdAt: string;
    updatedAt: string;
    userId: string;
    roleId: string;
  };
}
export interface IUserDataState {
  graphData?: {
    '@odata.context'?: string;
    businessPhones?: Array<string>;
    displayName?: string;
    givenName?: string;
    id?: string;
    jobTitle?: string;
    mail?: string;
    mobilePhone?: null | string;
    officeLocation?: string;
    preferredLanguage?: null | string;
    surname?: string;
    userPrincipalName?: string;
  } | null;
  userData?: {
    id: string;
    name: string;
    walletOffset: number;
    email: string;
    createdAt: string;
    updatedAt: string;
    organisations: Organisation[] | null | undefined;
    roles: Role[] | null | undefined;
    title: string;
    userBadges: Array<any> | undefined;
  } | null;
  currentOrganisation?: {
    location: string;
    lineOfBusiness: string;
    offeringPortfolio: string;
    offering: string;
    talentGroup: string;
    title: string;
    type: string;
    photo: any;
  };
  imageUrl: string;
}

export const initialItems: IUserDataState = {
  graphData: null,
  userData: null,
  currentOrganisation: {
    location: 'USI',
    lineOfBusiness: 'Consulting',
    offeringPortfolio: 'CnM',
    offering: 'AMnC',
    talentGroup: 'Studio',
    title: '',
    type: '',
    photo: '',
  },
  imageUrl: '',
};
// Actions
export enum ActionType {
  graphData,
  userData,
  currentOrganisation,
  imageUrl,
}

export interface setGraphData {
  type: ActionType.graphData;
  payload: any;
}

export interface setUserData {
  type: ActionType.userData;
  payload: any;
}

export interface setCurrentOrganisation {
  type: ActionType.currentOrganisation;
  payload: any;
}
export interface setImageUrl {
  type: ActionType.imageUrl;
  payload: any;
}
export type UserDataActions =
  | setGraphData
  | setUserData
  | setCurrentOrganisation
  | setImageUrl;

//reducer
export const setGraphData = (payload: any): setGraphData => ({
  type: ActionType.graphData,
  payload: payload,
});
export const setUserData = (payload: any): setUserData => ({
  type: ActionType.userData,
  payload: payload,
});
export const setCurrentOrganisation = (
  payload: any
): setCurrentOrganisation => ({
  type: ActionType.currentOrganisation,
  payload: payload,
});
export const setImageUrl = (payload: any): setImageUrl => ({
  type: ActionType.imageUrl,
  payload: payload,
});
export const UserDataContext = createContext<{
  userdatastate: IUserDataState;
  dispatch: Dispatch<UserDataActions>;
  fetchGraphDataApi: any;
  fetchUserDataApi: any;
  getUserProfilePicture: any;
}>({
  userdatastate: initialItems,
  dispatch: () => null,
  fetchGraphDataApi: () => null,
  fetchUserDataApi: () => null,
  getUserProfilePicture: () => null,
});

export const userDataReducer = (
  userdatastate: IUserDataState,
  action: UserDataActions
): IUserDataState => {
  switch (action.type) {
    case ActionType.graphData:
      const graphDatastate = {
        ...userdatastate,
        graphData: action.payload,
      };
      return graphDatastate;
    case ActionType.userData:
      const userDatastate = {
        ...userdatastate,
        userData: action.payload,
      };
      return userDatastate;
    case ActionType.graphData:
      const currentOrganisationstate = {
        ...userdatastate,
        currentOrganisation: action.payload,
      };
      return currentOrganisationstate;
    case ActionType.imageUrl:
      const imageUrlstate = {
        ...userdatastate,
        imageUrl: action.payload,
      };
      return imageUrlstate;
    default:
      return userdatastate;
  }
};
const fetchWrapper = UseFetchAPI();

export const UserDataContextProvider = (props: any) => {
  const { instance, inProgress } = useMsal();
  const [userdatastate, dispatch] = useReducer(userDataReducer, initialItems);
  const { setToster } = useContext(SnackbarContext);
  const callApiWithToken = async (accessToken: any, apiEndpoint: any) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);
    const options = {
      method: 'GET',
      headers: headers,
    };
    try {
      const response = await fetch(apiEndpoint, options);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGraphDataApi = async (account: any) => {
    try {
      const res = await instance.acquireTokenSilent({
        scopes: protectedResources.graphMe.scopes,
        account: account,
      });
      const tokeneRes = await callApiWithToken(
        res.accessToken,
        protectedResources.graphMe.endpoint
      );
      // TODO: remove this after other implementation

      dispatch(setGraphData(tokeneRes));
    } catch (error) {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          scopes: protectedResources.graphMe.scopes,
          account: account,
        });
        if (account && inProgress === 'none') {
          try {
            const res = await instance.acquireTokenPopup({
              scopes: protectedResources.graphMe.scopes,
            });
            const tokeneRes = callApiWithToken(
              res.accessToken,
              protectedResources.graphMe.endpoint
            );
            dispatch(setGraphData(tokeneRes));
          } catch (error) {
            setToster({ message: error, type: ToastMsg.ERROR });
          }
        }
      }
    }
  };

  const fetchUserDataApi = async (graphData: any) => {
    const formData = {
      email: graphData?.mail,
      name: graphData?.displayName,
      organisations: 'f996db17-3f92-4e87-9d8e-e669681dff9d',
      roles: '4928d147-12bf-4aff-88e5-3f7aa84e02d7',
      title: graphData?.jobTitle,
    };
    try {
      const res = await fetchWrapper.post({
        url: SDK_PATHS.findOrCreateUser,
        body: formData,
      });
      dispatch(setUserData(res));
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };
  const getUserProfilePicture = async (account: any) => {
    let res = { accessToken: '' };
    try {
      res = await instance.acquireTokenSilent({
        scopes: protectedResources.graphMe.scopes,
        account: account,
      });
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          scopes: protectedResources.graphMe.scopes,
          account: account,
        });
      }
    }
    try {
      const imageres = await fetch(
        `${protectedResources.graphMe.endpoint}/photo/$value`,
        {
          headers: { Authorization: `Bearer ${res.accessToken}` },
        }
      );
      if (!imageres?.ok) {
        return '';
      }
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(await imageres?.blob());
      dispatch(setImageUrl(blobUrl));
    } catch (err) {
      setToster({ message: err, type: ToastMsg.ERROR });
    }
  };

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      userdatastate,
      dispatch,
      fetchGraphDataApi,
      fetchUserDataApi,
      getUserProfilePicture,
    }),
    [
      userdatastate,
      dispatch,
      fetchGraphDataApi,
      fetchUserDataApi,
      getUserProfilePicture,
    ]
  );
  return (
    <UserDataContext.Provider value={contextValue}>
      {props.children}
    </UserDataContext.Provider>
  );
};
