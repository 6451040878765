import { useState } from 'react';
import useStyles from './ExcelPreviewTableStyle';
import {
  Box,
  Button,
  TextField,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material';
import { FFColors } from '../../theme';

interface Props {
  list?: any | JSX.Element;
  errors?: any | JSX.Element;
  badgeCategory?: any | JSX.Element;
  submitFn: any;
  revalidateFn: any;
  isError?: boolean;
}

export const ExcelPreviewTable: React.FC<Props> = (props) => {
  const [tabledata, setTableData] = useState(props.list);
  const handleChange = (event: any, row: any, index: number) => {
    const stateData = tabledata.slice();
    const rowData = Object.assign({}, row);
    rowData[event.target.name] = event.target.value;
    stateData[index] = rowData;
    setTableData(stateData);
  };
  // const handleEdit = (row: any, index: number, value: boolean) => {
  //   const stateData = tabledata.slice();
  //   const rowData = Object.assign({}, row);
  //   rowData['isEdit'] = value;
  //   stateData[index] = rowData;
  //   setTableData(stateData);
  // };
  // const handleDone = (row: any, index: number, value: boolean) => {
  //   const stateData = [...tabledata];
  //   row['isEdit'] = value;
  //   stateData[index] = row;
  //   setTableData(stateData);
  //   setData(stateData);
  // };

  const classes = useStyles();
  return (
    <>
      <TableContainer className={classes.excelPreviewSection} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              {props.badgeCategory === 'Event' ? (
                <>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Event Start Date</TableCell>
                </>
              ) : null}
              <TableCell>Employee Name</TableCell>
              <TableCell>Email id</TableCell>
              {props.badgeCategory === 'Event' ? (
                <TableCell>Participation Level</TableCell>
              ) : (
                <TableCell>Level</TableCell>
              )}
              <TableCell>Badge Identified</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(tabledata || []).map((row: any, index: number) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                {props.badgeCategory === 'Event' ? (
                  <>
                    <TableCell>{row.eventName}</TableCell>
                    <TableCell>{row.eventStartDate}</TableCell>
                  </>
                ) : null}
                <TableCell>
                  <TextField
                    required
                    disabled={!row.isEdit}
                    label="Required"
                    className={!row.isEdit ? classes.textfeild : ''}
                    value={row.name}
                    name={'name'}
                    onChange={(e) => handleChange(e, row, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    disabled={!row.isEdit}
                    label="Required"
                    name={'email'}
                    className={!row.isEdit ? classes.textfeild : ''}
                    value={row.email}
                    onChange={(e) => handleChange(e, row, index)}
                  />
                </TableCell>
                {props.badgeCategory === 'Event' ? (
                  <TableCell>{row.participationLevel}</TableCell>
                ) : (
                  <TableCell>{row.level}</TableCell>
                  // <TableCell>
                  //   <TextField
                  //     select
                  //     disabled={!row.isEdit}
                  //     label="Select"
                  //     value={row.level}
                  //     name={'level'}
                  //     className={!row.isEdit ? classes.textfeild : ''}
                  //     onChange={(e) => handleChange(e, row, index)}
                  //   >
                  //     {(row.levellist || []).map((option: any) => (
                  //       <MenuItem key={option} value={option}>
                  //         {option}
                  //       </MenuItem>
                  //     ))}
                  //   </TextField>
                  // </TableCell>
                )}

                <TableCell>{row.status === 'Success' ? 'Yes' : 'No'}</TableCell>
                <TableCell
                  sx={{
                    color:
                      row.status === 'Success'
                        ? FFColors.Success
                        : FFColors.Error,
                  }}
                >
                  {row.status}
                </TableCell>
                {/* {row.isEdit ? (
                  <TableCell>
                    <Close
                      className={classes.iconStyle}
                      onClick={() => setTableData(data)}
                    />
                    <Done
                      className={classes.iconStyle}
                      onClick={() => handleDone(row, index, false)}
                    />
                  </TableCell>
                ) : (
                  <TableCell>
                    {row.status === 'Success' ||
                    row.status === 'Duplicated' ? null : (
                      <ModeEdit
                        className={classes.iconStyle}
                        onClick={() => handleEdit(row, index, true)}
                      />
                    )}
                  </TableCell>
                )} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider className={classes.btnWrapperDivider} />
      <Box className={classes.btnWrapper}>
        <Box>
          <Button variant="outlined" href="/import-excel">
            Go Back
          </Button>
        </Box>
        <Box>
          {/* <Button
            variant="outlined"
            disabled={props.errors.length === 0}
            startIcon={<RefreshOutlined />}
            onClick={() => props.revalidateFn(props.list)}
          >
            Re-validate
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            disabled={props.isError}
            onClick={() => props.submitFn(props.list, props.badgeCategory)}
          >
            Submit request
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default ExcelPreviewTable;
