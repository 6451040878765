import { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './BadgeSelectedDetailsStyle';
import { STATUS } from '../../constants/constants';
import { SDK_PATHS } from '../../constants/SDK_PATHS';
import nft_image from '../../assets/nft.jpg';
import comments_icon from '../../assets/comment-icon.png';

interface Props {
  badgeData: any;
  badgeCategory: any;
  badgeSelectedLength: any;
  approveReject?: any;
  parent?: string;
}
// TODO Need to clean up div
export const BadgeSelectedDetails: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [badgeData, setBadgeData] = useState(props.badgeData[0]);
  const [badgeSelectedLength, setBadgeSelectedLength] = useState(
    props.badgeSelectedLength
  );
  const [badgeCategory, setBadgeCategory] = useState(props.badgeCategory);
  const [comments, setComments] = useState('');
  const [rejectClicked, setRejectClicked] = useState(false);

  const createdAt = new Date(badgeData?.createdAt).toLocaleDateString();

  useEffect(() => {
    setBadgeData(props.badgeData[0]);
    setBadgeCategory(props.badgeCategory);
    setBadgeSelectedLength(props.badgeSelectedLength);
  }, [props.badgeData, props.badgeCategory, props.badgeSelectedLength]);

  const reject = () => {
    setRejectClicked(!rejectClicked);
    if (rejectClicked) {
      props.approveReject(
        false,
        badgeData.id,
        badgeData.contractBadgeId,
        comments,
        props.badgeData
      );
    }
  };

  const first = (
    <Box className={classes.badgeDetailCardWrapper}>
      {badgeSelectedLength > 1 ? (
        <Typography className={classes.selectedCount}>
          {badgeSelectedLength} NFT's Selected
        </Typography>
      ) : null}
      {badgeSelectedLength > 2 ? (
        <Box className={classes.dummyCardSectiontwo}></Box>
      ) : null}
      {badgeSelectedLength > 1 ? (
        <Box
          className={
            classes.dummyCardSection +
            ' ' +
            `${badgeSelectedLength > 2 ? classes.dummyCardSection1 : ''} `
          }
        ></Box>
      ) : null}
      <Box
        className={
          classes.badgeDetailCard +
          ' ' +
          `${
            props.parent == 'profile' ? classes.badgeDetailCardFullWidth : ''
          } ` +
          ' ' +
          `${badgeSelectedLength > 2 ? classes.dummyCard2 : ''} ` +
          `${badgeSelectedLength === 2 ? classes.dummyCard1 : ''} `
        }
      >
        <Typography className={classes.header} gutterBottom>
          Selected NFT Details
        </Typography>
        <Divider className={classes.completeDivider} />
        {!badgeData ? (
          <Box
            sx={{
              minHeight: '333px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            No NFT Selected
          </Box>
        ) : (
          <>
            <Grid container>
              {/* <Grid item xs={8}> */}
              {badgeData?.assignedUser ? (
                <>
                  <Grid item xs={8}>
                    <Box sx={{ fontSize: 20, mt: 1 }}>
                      <b>{badgeData?.assignedUser.name}</b>
                    </Box>
                    <Box color="text.secondary" className={classes.emailField}>
                      {badgeData?.assignedUser.email}
                    </Box>
                    {/* <Divider sx={{ mt: 1.5, mb: 1 }} /> */}
                  </Grid>
                  <Grid item xs={4} className={classes.badgeImage}>
                    <img
                      src={`/api/internal/badges/manage/image/${badgeData.imageIpfsHash}`}
                      onError={(e: any) => {
                        e.target.src = nft_image;
                      }}
                    />
                  </Grid>
                  <Box className={classes.displayFieldHorizontal}>
                    <Box sx={{ mt: 1, mb: 1 }}>
                      <Typography className={classes.typographyColor}>
                        NFT Category
                      </Typography>
                      <Box className={classes.font}>{badgeCategory}</Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography className={classes.typographyColor}>
                        NFT Name
                      </Typography>
                      <Box className={classes.font}>{badgeData?.name}</Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Grid item xs={8}>
                    <Box sx={{ mt: 1.5, mb: 1 }}>
                      <Typography className={classes.typographyColor}>
                        NFT Category
                      </Typography>
                      <Box className={classes.font}>{badgeCategory}</Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography className={classes.typographyColor}>
                        NFT Name
                      </Typography>
                      <Box className={classes.font}>{badgeData?.name}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} className={classes.badgeImage}>
                    <img
                      src={`${SDK_PATHS.badges['manage'].getImage}/${badgeData.imageIpfsHash}`}
                      onError={(e: any) => {
                        e.target.src = nft_image;
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid>
              <Divider sx={{ mt: 1 }} />
              <Box className={classes.level}>
                <Box sx={{ mt: 1 }}>
                  <Typography className={classes.typographyColor}>
                    Level
                  </Typography>
                  <Box className={classes.font}>
                    {badgeData?.typeSpecificData?.level}
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography className={classes.typographyColor}>
                    {badgeData.type == 'Event' ? 'Event Date' : 'Date Created'}
                  </Typography>
                  <Box className={classes.font}>
                    {badgeData.type == 'Event'
                      ? badgeData.typeSpecificData.eventStartDate
                      : createdAt}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography className={classes.typographyColor}>
                  {props.parent == 'profile' ? 'Assigned' : 'Created'} By
                </Typography>
                <Box className={classes.font}>
                  {props.parent == 'profile'
                    ? badgeData?.userAssigned?.name
                    : badgeData?.userCreate?.name}
                </Box>
              </Box>
              {(badgeData?.status === 'Approved' ||
                badgeData?.status === 'Rejected') &&
              badgeData?.userApproved?.name ? (
                <Box sx={{ mt: 1 }}>
                  <Typography className={classes.typographyColor}>
                    Reviewed By
                  </Typography>
                  <div className={classes.font}>
                    {badgeData?.userApproved?.name}
                  </div>
                </Box>
              ) : null}
            </Grid>
          </>
        )}

        {badgeData?.description && (
          <>
            <Divider sx={{ mt: 1.5 }} />
            <Box sx={{ mt: 1.5 }}>
              <Typography className={classes.typographyColor}>
                Description
              </Typography>
              <Box className={classes.font}>{badgeData?.description}</Box>
            </Box>
          </>
        )}
        {/* {badgeData?.status !== 'Created' && badgeData?.comment && (
          <>
            <Divider sx={{ mt: 1.5 }} />
            <Box sx={{ mt: 1.5 }}>
              <Typography className={classes.typographyColor}>
                Comments
              </Typography>
              <Box className={classes.font}>{badgeData?.comment}</Box>
            </Box>
          </>
        )} */}
      </Box>
    </Box>
  );

  const second = (
    <Card
      className={
        classes.secondCard +
        ' ' +
        `${badgeSelectedLength > 2 ? classes.secondCardTwo : ''} ` +
        `${badgeSelectedLength === 2 ? classes.secondCardOne : ''} `
      }
    >
      <CardContent>
        {badgeData?.comment && badgeData?.status === STATUS.REJECTED ? (
          <Box>
            <Box className={classes.commentsBox}>
              <Typography className={classes.typographyColor}>
                Comments
              </Typography>
              <span className={classes.commentsBoxContent}>
                <img src={comments_icon} alt="icon"></img>
              </span>
            </Box>
            <Box className={classes.font}>{badgeData?.comment}</Box>
          </Box>
        ) : null}

        {badgeData?.status === STATUS.CREATED ||
        badgeData?.status === STATUS.ASSIGNED ? (
          <>
            {rejectClicked ? (
              <>
                <Typography className={classes.typographyColor}>
                  Comments <span className={classes.asterisk}>*</span>
                </Typography>
                <TextField
                  sx={{ mb: 2, width: '100%' }}
                  id="standard-multiline-static"
                  multiline
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  rows={3}
                  placeholder="e.g Reason for rejection"
                />
              </>
            ) : (
              <Typography className={classes.commentsRequired}>
                Rejecting would require comments
                <span className={classes.asterisk}>*</span>
              </Typography>
            )}
            <Box className={classes.buttons}>
              {rejectClicked ? (
                <Button
                  onClick={() => setRejectClicked(false)}
                  variant="contained"
                >
                  Back
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    props.approveReject(
                      true,
                      badgeData.id,
                      badgeData.contractBadgeId,
                      comments,
                      props.badgeData
                    )
                  }
                  variant="contained"
                  color="secondary"
                  className={classes.approvedRejectedButton}
                >
                  Approve
                </Button>
              )}

              <Button
                onClick={reject}
                variant="outlined"
                className={classes.approvedRejectedButton}
                disabled={rejectClicked && !comments}
              >
                Reject
              </Button>
            </Box>
          </>
        ) : null}
      </CardContent>
    </Card>
  );

  return (
    <>
      {first}
      {/* {second} */}

      {badgeData?.comment ||
      badgeData?.status === STATUS.CREATED ||
      badgeData?.status === STATUS.REJECTED ||
      badgeData?.status === STATUS.ASSIGNED
        ? second
        : null}
    </>
  );
};
