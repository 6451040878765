import React from 'react';
import { Box, Button, Typography, Divider, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BADGE_CATEGORY,
  EXCEL_DATE_ERROR_MESSAGE,
} from '../../constants/constants';
import { StepHeader } from '../../components/StepHeader/StepHeader';
import useStyles from './ImportExcelStyle';
import { useState, useCallback, useContext } from 'react';
import { CSVFileUpload } from '../../components/CSVFileUpload/CSVFileUpload';
import { AssignmentValidateContext } from '../../contexts/AssignmentValidateContext';
import { ExcelPreview } from '../../components/ExcelPreview/ExcelPreview';
import { SelectBox } from '../../components/SelectBox/SelectBox';
import { UserDataContext } from '../../contexts/UserDataContext';
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs';
import { LoaderContext } from '../../contexts/LoaderContext';

export const ImportExcel: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const { dispatch, setLoading } = useContext(LoaderContext);
  const { state, validateBadgeAssignmentAPI, submitAssignmentAPI } = useContext(
    AssignmentValidateContext
  );
  const { userdatastate } = useContext(UserDataContext);

  const [badgeCategory, setBadgeCategory] = useState('');
  const [excelData, setExcelData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOccuredInExcelDate, setErrorOccuredInExcelDate] = useState(false);
  const [isExcelData, setIsExcelData] = useState(false);
  const badgeCategoryChange = (value: string) => {
    setBadgeCategory(value);
  };
  const eventJSONFormater = (data: any) => {
    const eventData: any = [];
    let obj;
    data.map((rawInnerData: any) => {
      let innerData: any = {};
      innerData = Object.fromEntries(
        Object.entries(rawInnerData).map(([k, v]) => [
          typeof k === 'string' ? k.trim() : k,
          v,
        ])
      );
      if (badgeCategory === 'Event') {
        let formatedDate;
        const toUTC = new Date(innerData['Event Start Date']).toUTCString();

        if (toUTC && toUTC !== 'Invalid Date') {
          const d = new Date(toUTC);
          const dd = String(d.getUTCDate()).padStart(2, '0');
          const mm = String(d.getUTCMonth() + 1).padStart(2, '0');
          const yyyy = d.getUTCFullYear();
          formatedDate = mm + '/' + dd + '/' + yyyy;
        } else {
          setErrorOccuredInExcelDate(true);
          setErrorMessage(EXCEL_DATE_ERROR_MESSAGE);
        }
        obj = {
          eventName: innerData['Event Name'],
          eventStartDate: formatedDate,
          name: innerData['Name'],
          email: innerData['Email'],
          participationLevel: innerData['Participation Level'],
          comment: innerData['Comment'],
        };
      } else {
        obj = {
          name: innerData['Name'],
          email: innerData['Email'],
          level: innerData['Level'],
        };
      }
      eventData.push(obj);
    });
    return eventData;
  };
  const getExcelData = (assignmentList: any, status: boolean) => {
    const eventData = eventJSONFormater(assignmentList);
    setExcelData(eventData);
    setIsExcelData(status);
  };
  const validateClick = async () => {
    dispatch(setLoading(true));
    const result = await validateAssignment(excelData, badgeCategory);
    if (result) {
      setIsPreview(true);
    }
    dispatch(setLoading(false));
  };
  const validateAssignment = useCallback(
    async (assignmentList: any, badgeCategory: string) => {
      return await validateBadgeAssignmentAPI(assignmentList, badgeCategory);
    },
    [userdatastate]
  );

  const submitAssignment = useCallback(async (assignmentList: any) => {
    await submitAssignmentAPI(assignmentList);
    navigate('/assign-badges');
  }, []);
  return (
    <Container className={classes.containerSec}>
      <Breadcrumb
        previousScreen={[{ name: 'Assign NFT', url: '/assign-badges' }]}
        currentScreenName="Import excel"
      />
      {!isPreview && (
        <Box>
          <Typography variant="h2" gutterBottom>
            Import Excel
          </Typography>
          <Typography variant="body1" gutterBottom>
            Begin assigning the NFT by importing excel file.
          </Typography>
        </Box>
      )}
      <Box sx={{ width: '100%' }}>
        {!isPreview && (
          <>
            <Box className={classes.importExcelWrapper}>
              <Box className={classes.categorySelectWrapper}>
                <StepHeader title={'Step 1'} active={true} />
                <Box className={classes.categorySelectBox}>
                  <Typography variant="h3" gutterBottom>
                    Category
                  </Typography>
                  <SelectBox
                    title={'Choose NFT Category'}
                    selectBoxChange={badgeCategoryChange}
                    list={BADGE_CATEGORY}
                    placeholder={'Select from list'}
                  />
                </Box>
              </Box>
              {badgeCategory && (
                <Box className={classes.categorySelectWrapper}>
                  <StepHeader title={'Step 2'} active={false} />
                  <Box className={classes.fileUploadBoxWrapper}>
                    <CSVFileUpload
                      buttonTitle="Choose File"
                      acceptFiles=".csv,.xlsx,.xls"
                      validateDoc={getExcelData}
                      downloadTemplate="/TemplateforAssigningbadges.xlsx"
                      errorMessage={errorMessage}
                    ></CSVFileUpload>
                  </Box>
                </Box>
              )}
            </Box>
            {badgeCategory && (
              <Box className={classes.validateSec}>
                <Divider />
                <Button
                  className={classes.validateExcelBtn}
                  variant="contained"
                  disabled={!isExcelData || errorOccuredInExcelDate}
                  onClick={validateClick}
                >
                  Validate the excel
                </Button>
              </Box>
            )}
          </>
        )}
        {badgeCategory && isPreview ? (
          <ExcelPreview
            validateAssignmentList={state.validatedAssignmentList}
            errors={state.errors}
            duplicated={state.duplicated}
            revalidateFn={validateAssignment}
            submitFn={submitAssignment}
            badgeCategory={badgeCategory}
            isError={state.isError}
          ></ExcelPreview>
        ) : null}
      </Box>
    </Container>
  );
};
