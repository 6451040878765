import { makeStyles } from '@mui/styles';
import { spacetheme, FFColors } from '../../theme';
import { Theme } from '@mui/system';
export default makeStyles((theme: Theme) => ({
  importExcelWrapper: {
    display: 'flex',
    marginTop: spacetheme.spacing(11),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  containerSec: {
    maxWidth: '1328px',
    width: '100%',
    padding: spacetheme.spacing(10),
    margin: '0 auto',
    marginBottom: '50px',
  },
  categorySelectWrapper: {
    width: '427px',
    marginRight: spacetheme.spacing(10),
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
  },
  categorySelectBox: {
    backgroundColor: FFColors.White,
    border: `1px solid ${FFColors.BorderGray}`,
    padding: spacetheme.spacing(10, 12, 12, 12),
    marginTop: spacetheme.spacing(10),
    '& h3': {
      marginBottom: spacetheme.spacing(10),
    },
  },
  fileUploadBoxWrapper: {
    marginTop: spacetheme.spacing(10),
  },
  header: {
    marginBottom: spacetheme.spacing(0),
  },
  backButton: {
    marginRight: spacetheme.spacing(7),
  },
  validateExcelBtn: {
    backgroundColor: FFColors.Success,
    marginTop: spacetheme.spacing(6),
  },
  validateSec: {
    width: '875px',
    marginTop: spacetheme.spacing(12),
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
  },
}));
