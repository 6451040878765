import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export default makeStyles((theme: Theme) => ({
  maincontainer: {
    maxWidth: '1328px',
    margin: '0 auto',
  },
  nftBtn: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
      paddingRight: '4px',
      '& .MuiButton-root.MuiButton-contained': {
        padding: '3px',
        fontSize: '12px',
      },
    },
  },
  profileBox: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      '& .MuiAvatar-root': {
        width: '30px',
        height: '30px',
        fontSize: '14px',
      },
    },
  },
}));
