import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { FFColors } from '../../theme';
import useStyles from './MessageSnackbarStyle';
import { SnackbarContext, setMessage } from '../../contexts/SnackbarContext';
const TRANSITION_TIMEOUT = 400;

export const MessageSnackbar: React.FC = () => {
  const { state } = useContext(SnackbarContext);
  const [open, setOpen] = useState(state.message ? true : false);
  const timeoutRef = useRef<number>(0);
  const classes = useStyles();
  useEffect(() => {
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  const handleClose = () => {
    window.clearTimeout(timeoutRef.current);
    setOpen(false);
    timeoutRef.current = window.setTimeout(
      () => setMessage({ message: '', type: '' }),
      TRANSITION_TIMEOUT
    );
  };

  useEffect(() => {
    setOpen(state.message ? true : false);
  }, [state]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={state.type === 'error' ? 60000 : 4000}
      className={classes.snackbarWrapper}
    >
      <Alert
        onClose={handleClose}
        className={classes.snackbarAlert}
        sx={{
          width: '100%',
          backgroundColor:
            state.type === 'error' ? FFColors.Error : FFColors.Success,
        }}
        variant={'filled'}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};
