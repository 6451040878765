import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme, FONT_SIZE, themeOptions } from '../../theme';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  logoSection: {
    display: 'flex',
    alignItems: 'center',
    '& hr': {
      borderColor: '#53565A',
      marginLeft: '16px',
      marginRight: '16px',
      height: '16px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '6px',
        marginRight: '6px',
      },
    },
    '& p': {
      marginBottom: 0,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
    },
  },
  logoimg: {
    width: 79,
    [theme.breakpoints.down('md')]: {
      width: 75,
    },
  },
}));
