import { createTheme, ThemeOptions } from '@mui/material';

export const DEFAULT_BORDER_RADIUS = '8px';
export const FONT_SIZE_16 = '16px';
export const DEFAULT_HIST_HEIGHT = 175;
export const DEFAULT_TIMELINE_HEIGHT = 500;
export const DEFAULT_PADDING = 3;
export const DEFAULT_PAGE_LIMITS = [5, 10, 25];
export const DEFAULT_SPACING = 3;
export const BADGELISTVIEW_BOX_SHADOW =
  'inset 0px 1px 0px #D0D0CE, inset -1px -1px 0px #D0D0CE, inset -1px 0px 0px #D0D0CE, inset 1px 0px 0px #D0D0CE';
export const FONT_SIZE = {
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  56: '56px',
};

export enum FFColors {
  Green = '#388e3c',
  Orange = '#ff8a00',
  Pink = '#cc01ab',
  Purple = '#6b00f2',
  Red = '#e1111e',
  Yellow = '#ffca00',
  White = '#FFFFFF',
  Grey = '#808080a8',
  lightGrey = '#0000003d',
  LightGrey = '#E8E8E8',
  Gray = '#777777',
  Black = '#000000',
  Success = '#26890D',
  Error = '#DA291C',
  TextGray = '#53565A',
  BorderGray = '#D0D0CE',
  BgGray = '#E5E5E5',
  Placeholder = '#63666A',
  CardBg = '#BBBCBC',
  ActiveGreen = '#86BC25',
}

export const spacetheme = createTheme({
  spacing: [
    0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60,
    64, 68, 72,
  ],
});
export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#388e3c',
    },
    background: {
      default: '#e4e4e4',
      paper: '#ffffff',
    },
    text: {
      primary: '#101010',
      secondary: 'rgba(16,16,16,0.7)',
      disabled: 'rgba(16,16,16,0.5)',
    },
    info: {
      main: FFColors.Pink,
    },
    success: {
      main: FFColors.Purple,
    },
    warning: {
      main: FFColors.Yellow,
    },
    error: {
      main: FFColors.Red,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#12171d',
          width: '100%',
          borderRadius: DEFAULT_BORDER_RADIUS,
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderRadius: DEFAULT_BORDER_RADIUS,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: DEFAULT_BORDER_RADIUS,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          fontSize: '12px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          borderRadius: 0,
          boxShadow: 'none',
          textTransform: 'none',
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '14px',
    //     },
    //   },
    // },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: 'overlay',
          // scrollbarColor: '#12171d',
          // '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          //   cursor: 'pointer',
          //   backgroundColor: '#12171d',
          // },
          // '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          //   borderRadius: 8,
          //   backgroundColor: '#1e242a',
          //   border: '3px solid #12171d',
          // },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '40px',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '36px',
    },
    h3: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },
    h4: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    body2: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
    },
  },
};

export const altScrollbarStyle = {
  scrollbarColor: '#1e242a',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    cursor: 'pointer',
    backgroundColor: '#1e242a',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: '#12171d',
    border: '3px solid #1e242a',
  },
};

export const manageBadgeFullPageModal = {
  position: `absolute`,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `80%`,
  height: `80%`,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};
