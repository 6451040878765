import { makeStyles } from '@mui/styles';
import { FFColors, spacetheme, FONT_SIZE } from '../../theme';
export default makeStyles(() => ({
  uploadBoxWrapper: {
    backgroundColor: FFColors.White,
    border: `1px solid ${FFColors.BorderGray}`,
    padding: spacetheme.spacing(10, 12, 12, 12),
  },
  downloadTemplate: {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: FONT_SIZE[12],
    lineHeight: '16px',
    textDecoration: 'underline',
    color: FFColors.Success,
    textAlign: 'right',
  },
  disclaimerSec: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacetheme.spacing(4, 0),
  },
}));
