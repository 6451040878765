import useStyles from './ExcelPreviewStyle';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ExcelPreviewTable } from '../ExcelPreviewTable/ExcelPreviewTable';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
interface Props {
  validateAssignmentList: any;
  errors: any;
  duplicated: any;
  revalidateFn: any;
  submitFn: any;
  badgeCategory: any;
  isError: boolean;
}

export const ExcelPreview: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Typography variant="h2" gutterBottom>
          Upload Preview
        </Typography>
        <Typography variant="body1" gutterBottom>
          The NFT creation form will be populated based on the category
          selection
        </Typography>
      </Box>
      <Box className={classes.nftCountWrapper}>
        <Box>
          <Typography variant="body2" gutterBottom>
            NFT Uploaded
          </Typography>
          <Typography variant="h2" gutterBottom>
            {props.validateAssignmentList.length}
          </Typography>
        </Box>
        <Divider />
        <Box className={props.isError ? classes.errorMsgRed : classes.errorMsg}>
          {props.isError ? (
            <>
              <Typography variant="body2" gutterBottom>
                Errors Found
              </Typography>
              <Box className={classes.errorExclamation}>!</Box>
            </>
          ) : (
            <>
              <Typography variant="body2" gutterBottom>
                No Errors Found
              </Typography>
              <DoneIcon />
            </>
          )}
        </Box>
      </Box>
      <ExcelPreviewTable
        errors={props.errors}
        list={props.validateAssignmentList}
        revalidateFn={props.revalidateFn}
        submitFn={props.submitFn}
        isError={props.isError}
        badgeCategory={props.badgeCategory}
      />
    </Box>
  );
};
